/* eslint-disable import/prefer-default-export */
import { theme } from '@notino/react-styleguide'
import styled from 'styled-components'
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../../styles/constants'

export const ToggleButtonMobile = styled.button`
	${theme.typography.bodyRegular};
	color: white;
	background: ${theme.color.background.highlight};
	padding: 12px 12px;
	border-radius: 1000px;
	border: none;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`
