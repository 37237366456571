import React from 'react'
import { IconSolidInfo } from '@notino/react-styleguide'
import * as SC from './BasicSalonStyles'
import { ConfigResponse, SalonDetail } from '../../../../types/types'
import { SALON_PAGE_MOBILE_BREAKPOINT_KEY } from '../../../../styles/constants'
import GoogleMaps from '../../../../components/GoogleMaps/GoogleMaps'
import { DesktopOnly, MobileOnly } from '../../../../styles/helpers'
import SalonContacts from '../SalonContacts/SalonContacts'
import useMessages from '../../../../hooks/useMessages'

type Props = {
	salonData: SalonDetail
	countriesData: ConfigResponse['rolloutCountries']
	assetsPath: string
	googleMapsApiKey: string
	googleMapsUrl: string
}

const BasicSalon = (props: Props) => {
	const { salonData, countriesData, assetsPath, googleMapsApiKey, googleMapsUrl } = props
	const { messages } = useMessages()

	const linkFromTranslations = messages?.['notino-partner-link']
	const FALLBACK_LINK = 'https://partner.notino.cz/#jak-na-to'
	const link = !linkFromTranslations || linkFromTranslations === '_NEPRELOZENE_' ? FALLBACK_LINK : linkFromTranslations

	const partnerLink = (
		<SC.NotinoPartnerLinkContainer>
			<IconSolidInfo color={'icon.primary'} width='16' />
			<SC.NotinoPartnerLink href={link} target='_blank' rel='noreferrer'>
				{messages?.['Is this your salon?']}
			</SC.NotinoPartnerLink>
		</SC.NotinoPartnerLinkContainer>
	)

	return (
		<div>
			{salonData.name && <SC.Heading>{salonData.name}</SC.Heading>}

			<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<SC.MapContainer id={'google-map'}>
					<GoogleMaps
						assetsPath={assetsPath}
						googleMapsApiKey={googleMapsApiKey}
						disabled
						center={{ lat: salonData?.address?.latitude || 0, lng: salonData?.address?.longitude || 0 }}
						showOneMarker // marker position is equal to the center in this case
					/>
				</SC.MapContainer>
				<SalonContacts salonData={salonData} countriesData={countriesData} googleMapsUrl={googleMapsUrl} />
				{partnerLink}
			</MobileOnly>

			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<SC.Columns>
					<SC.MainColumn>
						<SC.MapContainer>
							<GoogleMaps
								assetsPath={assetsPath}
								googleMapsApiKey={googleMapsApiKey}
								disabled
								center={{ lat: salonData?.address?.latitude || 0, lng: salonData?.address?.longitude || 0 }}
								showOneMarker // marker position is equal to the center in this case
							/>
						</SC.MapContainer>
					</SC.MainColumn>
					<SC.SideColumn>
						<SalonContacts salonData={salonData} countriesData={countriesData} googleMapsUrl={googleMapsUrl} />
						{partnerLink}
					</SC.SideColumn>
				</SC.Columns>
			</DesktopOnly>
		</div>
	)
}

export default BasicSalon
