import React from 'react'
import { Button, ButtonModel, Ratings } from '@notino/react-styleguide'
import dayjs from 'dayjs'
import useMessages from '../../../../hooks/useMessages'
import { Ratings as RatingsData, SalonDetail, SalonReviewsResponse } from '../../../../types/types'
import * as SC from './SalonPageReviewsStyles'
import SalonRatings from '../SalonRatings/SalonRatings'
import useSalonReviews from '../../../../hooks/useSalonReviews'

type Props = {
	salonData: SalonDetail
	reviewsData: SalonReviewsResponse | undefined
}

const SalonPageReviews = (props: Props) => {
	const { messages } = useMessages()
	const { salonData, reviewsData } = props
	const { reviews, reviewsPage, fetchMoreReviews } = useSalonReviews(salonData?.id, reviewsData)

	const totalPages = reviewsData?.pagination.totalPages
	const hasMoreReviews = totalPages ? reviewsPage < totalPages : false

	return (
		<SC.SalonReviewsContainer>
			<SC.Heading>{messages?.Reviews}</SC.Heading>
			<SC.AverageRatingContainer>
				<SC.AverageRatingValue>{salonData?.ratingsData?.rating}</SC.AverageRatingValue>
				<SC.AverageRatingStars>
					<Ratings ratingId='salon-average-rating' foreground={'background.highlight'} rating={salonData?.ratingsData?.rating} size={13} />
				</SC.AverageRatingStars>
			</SC.AverageRatingContainer>
			<SC.RatingsContainer>
				<SalonRatings ratingsData={salonData?.ratingsData || ({} as RatingsData)} />
			</SC.RatingsContainer>
			{reviews && (
				<>
					<SC.ReviewsContainer>
						{reviews.map((review) => {
							return (
								<SC.Review key={review.id}>
									<SC.ReviewMessage>{review.reviewMessage}</SC.ReviewMessage>
									<SC.ReviewInfo>
										<Ratings ratingId={`salon-rating-${review.id}`} foreground={'background.highlight'} rating={review.rating} size={12} />
										<SC.ReviewerName>{review.reviewerName}</SC.ReviewerName>
										<SC.ReviewDate>{dayjs(review.createdAt).format('DD.MM.YYYY')}</SC.ReviewDate>
									</SC.ReviewInfo>
								</SC.Review>
							)
						})}
					</SC.ReviewsContainer>
					{hasMoreReviews && (
						<SC.ReviewsButtonContainer>
							<Button onClick={() => fetchMoreReviews()} buttonStyle={ButtonModel.Styles.secondary}>
								{messages?.['Show more']}
							</Button>
						</SC.ReviewsButtonContainer>
					)}
				</>
			)}
		</SC.SalonReviewsContainer>
	)
}

export default SalonPageReviews
