import { theme } from '@notino/react-styleguide'
import styled, { css } from 'styled-components'
import { BREAKPOINTS, CONTAINER_X_PADDING } from '../../../../styles/constants'

export const Container = styled.div`
	width: 100%;
`

export const EnumsContainer = styled.div`
	margin-bottom: 60px;

	@media (max-width: ${BREAKPOINTS.md}) {
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0px;
		}
	}
`

export const EnumsTitleText = styled.div`
	${theme.typography.titleDisplay}
`

export const EnumsContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 28px;

	margin-top: 20px;

	@media (max-width: ${BREAKPOINTS.md}) {
		flex-wrap: nowrap;
		overflow-x: auto;

		/* stretching content to full screen width - "overriding" the container padding */
		margin-left: -${CONTAINER_X_PADDING}px;
		margin-right: -${CONTAINER_X_PADDING}px;
		padding-left: ${CONTAINER_X_PADDING}px;
		padding-right: ${CONTAINER_X_PADDING}px;
	}
`

export const EnumsItem = styled.div`
	${theme.typography.bodyRegular}

	width: 100px;
	text-align: center;
	flex-shrink: 0;
	padding-bottom: 4px; /* gap to (potential) horizontal scrollbar */
	display: grid;
	place-items: center;

	@media (max-width: ${BREAKPOINTS.md}) {
		width: 80px;
	}
`

export const EnumsItemImage = styled.img<{ $isRounded?: boolean }>`
	aspect-ratio: 1;
	object-fit: contain;

	${(p) =>
		p.$isRounded &&
		css`
			border-radius: 50%;
		`}
`

export const LanguageContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
	padding-bottom: 4px; /* gap to (potenatial) horizontal scrollbar */
`

export const LanguageFlag = styled.div`
	width: 24px;
	height: 16px;
	display: flex;
	align-items: center;

	img {
		vertical-align: unset !important; // overwriting notino default style
	}
`

export const LanguageTitle = styled.div`
	${theme.typography.bodyLarge}
	white-space: nowrap;
`

export const AboutUsText = styled.p`
	${theme.typography.bodyLarge}
	margin-bottom: 60px;
`
