import { styled } from '@notino/react-styleguide'
import { BREAKPOINTS } from '../../../../styles/constants'

export const RatingWrapper = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`

export const ProgressLineWithCount = styled.div`
	display: flex;
	align-items: center;

	& > svg {
		height: 10px;
		width: 100%;
	}
`

export const Count = styled.span`
	display: inline-block;
	margin-left: 8px;
`

export const SalonReviewsContainer = styled.div`
	width: 50%;

	@media (max-width: ${BREAKPOINTS.md}) {
		width: 100%;
	}
`
