import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { useForm } from 'react-hook-form'
import { IDropdownOption } from '@notino/react-styleguide'
import { CollapsibleType } from 'rc-collapse/es/interface'

// utils
import {
	DEFAULT_DATE_FORMAT,
	FILTER_COUNTS_CHANGE_DEBOUNCE_TIME_MS,
	FORM,
	OPENING_HOURS_STATUS,
	SALONS_FILTER_TYPE,
	SORTING_OPTION
} from '../../../../utils/enums'
import { ApiContext } from '../../../../utils/apiProvider'
import { areValuesInArrayEqual, getCategoryIDs, SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'
import { EXACT_RATING_OPTIONS } from '../../../../utils/tsxHelper'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import {
	FilterValuesType,
	GetSalonsFilterCountsParams,
	Cosmetic,
	CustomDropdownOption,
	GetSalonsFilterCountResponse,
	Language,
	LoadSalonsFromFiltersParams,
	SalonsPageQueryType,
	ServiceTotalPriceCurrencyCode
} from '../../../../types/types'

// components
import Tag from '../../../../atoms/Tag/Tag'
import Collapse from '../FilterCollapse/FilterCollapse'
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import SwitchField from '../../../../formFields/SwitchFormField/SwitchFormField'
import FilterSidebarPrice from './FilterSidebarPrice'
import FilterSidebarExactRating from './FilterSidebarExactRatings'
import FilterSidebarCosmetics from './FilterSidebarCosmetics'
import FilterSidebarLanguages from './FilterSidebarLanguages'
import FilterSidebarOrderBy from './FilterSidebarOrderBy'
import FilterSidebarDate from './FilterSidebarDate'
import PriceRangeFormField from '../../../../formFields/PriceRangeFormField/PriceRangeFormField'
import HookFormField from '../../../../formFields/HookFormField'
import CheckboxGroupFormField from '../../../../formFields/CheckboxGroupFormField/CheckboxGroupFormField'
import RadioButtonsFormField from '../../../../formFields/RadioButtonsFormField/RadioButtonsFormField'

// styles
import * as SC from './FilterSidebarStyles'

// types
type FilterSidebarProps = {
	query: SalonsPageQueryType
	submittedFilterValues: FilterValuesType
	defaultFilterValues: FilterValuesType
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	filterCounts: GetSalonsFilterCountResponse | undefined
	filterSidebarOpen: boolean
	setFilterSidebarOpen: (open: boolean) => void
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterSidebarDetailType: SALONS_FILTER_TYPE | null
	setFilterSidebarDetailType: (filterSidebarDetailType: SALONS_FILTER_TYPE) => void
	/*
	 * filterDetailOpenedDirectly - user opened a filter detail directly and not through main filter sidebar
	 * eg. he clicked on "Price" chip or "Date" chip
	 * there's a different behavior when he opens a detail directly and through main sidebar
	 * for example, we will close detail and submit detail immediately and not redirect him back to main filter sidebar
	 */
	filterDetailOpenedDirectly: boolean
	setFilterDetailOpenedDirectly: (open: boolean) => void
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	sortingOptions: IDropdownOption[]
	initialLanguages: Language[]
	initialCosmetics: Cosmetic[]
	currencySymbol: string | undefined
	currencyCode: ServiceTotalPriceCurrencyCode | undefined
	initialClientDataLoading?: boolean
	topLevelCategoryID?: string
	assetsPath: string
	maxAvResTimeSlotDateRange: number | undefined
}

const defaultFilterKeys = [SALONS_FILTER_TYPE.PRICE]

// component
const FilterSidebar = (props: FilterSidebarProps) => {
	const {
		filterSidebarOpen,
		setFilterSidebarOpen,
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		filterSidebarDetailType,
		setFilterSidebarDetailType,
		setFilterDetailOpenedDirectly,
		sortingOptions,
		initialLanguages,
		initialCosmetics,
		currencySymbol,
		currencyCode,
		loadSalonsFromFilters,
		query,
		filterCounts,
		initialClientDataLoading,
		topLevelCategoryID,
		submittedFilterValues,
		defaultFilterValues,
		filterItemsConfig,
		assetsPath,
		maxAvResTimeSlotDateRange
	} = props
	const { messages } = useMessages()

	// get api client for requests outside of server
	const { apiBrowser } = useContext(ApiContext)

	const [activeFilter, setActiveFilter] = useState<React.Key[]>(defaultFilterKeys)
	const [previewFilterCounts, setPreviewFilterCounts] = useState<GetSalonsFilterCountResponse | undefined>(undefined)
	const [loadingFilterCounts, setLoadingFilterCounts] = useState(false)

	// default filter values
	const {
		serviceTotalPriceRange: { priceFrom: serviceTotalPriceFromDefaultValue, priceTo: serviceTotalPriceToDefaultValue },
		hasAvailableReservationSystem: availableRSDefaultValue,
		openingHours: openingHoursDefaultValue,
		exactRating: ratingDefaultValue,
		cosmeticIDs: cosmeticsDefaultValue,
		languageIDs: languagesDefaultValue,
		orderBy: orderByDefaultValue,
		availableReservations: { timeSlot: avResTimeSlotDateDefaultValue, dateFrom: avResTimeSlotDateFromDefaultValue, dateTo: avResTimeSlotDateToDefaultValue }
	} = defaultFilterValues

	// submitted filter values
	// needs to be memoized to prevent unexpected reset of internal filter values
	const {
		serviceTotalPriceRange: { priceFrom: submittedServiceTotalPriceFrom, priceTo: submittedServiceTotalPriceTo },
		hasAvailableReservationSystem: submittedHasAvailableReservationSystem,
		openingHours: submittedOpeningHours,
		exactRating: submittedExactRating,
		cosmeticIDs: submittedCosmeticIDs,
		languageIDs: submittedLanguageIDs,
		orderBy: submittedOrderBy,
		availableReservations: { timeSlot: submittedAvResTimeSlotDate, dateFrom: submittedAvResTimeSlotDateFrom, dateTo: submittedAvResTimeSlotDateTo }
	} = submittedFilterValues

	// internal filter values - no submitted - just for component state
	const {
		control,
		handleSubmit,
		reset,
		getValues,
		watch,
		setValue,
		formState: { dirtyFields }
	} = useForm<FilterValuesType>({
		defaultValues: submittedFilterValues
	})

	const {
		serviceTotalPriceRange: { priceFrom: serviceTotalPriceFrom, priceTo: serviceTotalPriceTo },
		hasAvailableReservationSystem,
		openingHours,
		exactRating,
		cosmeticIDs,
		languageIDs,
		orderBy,
		availableReservations: { timeSlot: avResTimeSlotDate, dateFrom: avResTimeSlotDateFrom, dateTo: avResTimeSlotDateTo }
	} = watch()

	const filterValues = useMemo(
		() => ({
			serviceTotalPriceRange: { priceFrom: serviceTotalPriceFrom, priceTo: serviceTotalPriceTo },
			hasAvailableReservationSystem,
			openingHours,
			exactRating,
			cosmeticIDs,
			languageIDs,
			orderBy,
			availableReservations: { timeSlot: avResTimeSlotDate, dateFrom: avResTimeSlotDateFrom, dateTo: avResTimeSlotDateTo }
		}),
		[
			serviceTotalPriceFrom,
			serviceTotalPriceTo,
			hasAvailableReservationSystem,
			openingHours,
			exactRating,
			cosmeticIDs,
			languageIDs,
			orderBy,
			avResTimeSlotDate,
			avResTimeSlotDateFrom,
			avResTimeSlotDateTo
		]
	)

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	const isPriceFilterEqualToDefault = serviceTotalPriceFrom === serviceTotalPriceFromDefaultValue && serviceTotalPriceTo === serviceTotalPriceToDefaultValue
	const isHasAvailableRSEqualToDefault = hasAvailableReservationSystem === availableRSDefaultValue
	const isOpeningHoursEqualToDefault = openingHours === openingHoursDefaultValue
	const isRatingFilterEqualToDefault = areValuesInArrayEqual(exactRating, ratingDefaultValue)
	const isCosmeticsFilterEqualToDefault = areValuesInArrayEqual(cosmeticIDs, cosmeticsDefaultValue)
	const isLanguagesFilterEqualToDefault = areValuesInArrayEqual(languageIDs, languagesDefaultValue)
	const isOrderByFilterEqualToDefault = orderBy === orderByDefaultValue

	let isDateFilterEqualToDefault = true
	if (avResTimeSlotDateFrom) {
		isDateFilterEqualToDefault = avResTimeSlotDateFromDefaultValue ? avResTimeSlotDateFrom.isSame(avResTimeSlotDateFromDefaultValue, 'date') : false
	}
	if (avResTimeSlotDateTo) {
		isDateFilterEqualToDefault = avResTimeSlotDateToDefaultValue ? avResTimeSlotDateTo.isSame(avResTimeSlotDateToDefaultValue, 'date') : false
	}
	if (avResTimeSlotDate !== avResTimeSlotDateDefaultValue) {
		isDateFilterEqualToDefault = false
	}

	const isFormEqualToDefault =
		isPriceFilterEqualToDefault &&
		isHasAvailableRSEqualToDefault &&
		isOpeningHoursEqualToDefault &&
		isRatingFilterEqualToDefault &&
		isCosmeticsFilterEqualToDefault &&
		isLanguagesFilterEqualToDefault &&
		isDateFilterEqualToDefault &&
		isOrderByFilterEqualToDefault

	// custom comparison for dirty values for "availableReservations", because those are Dayjs values
	let isDateFilterPristine = true
	if (avResTimeSlotDateFrom || submittedAvResTimeSlotDateFrom) {
		isDateFilterPristine = submittedAvResTimeSlotDateFrom ? !!avResTimeSlotDateFrom?.isSame(submittedAvResTimeSlotDateFrom, 'date') : false
	}
	if (avResTimeSlotDateTo || submittedAvResTimeSlotDateTo) {
		isDateFilterPristine = submittedAvResTimeSlotDateTo ? !!avResTimeSlotDateTo?.isSame(submittedAvResTimeSlotDateTo, 'date') : false
	}
	if (avResTimeSlotDate !== submittedAvResTimeSlotDate) {
		isDateFilterPristine = false
	}

	// otherwise check dirtyFields object that is provided by "React hook form"
	const isDirty = !!Object.keys(dirtyFields).filter((key) => key !== 'availableReservations').length || !isDateFilterPristine

	// show / hide some filters
	const showCosmeticsFilters = previewFilterCounts ? !!(Object.values(previewFilterCounts.cosmetics).some((value) => value) || cosmeticIDs.length) : true
	const showRatingsFilters = previewFilterCounts ? !!(Object.values(previewFilterCounts.exactRating).some((value) => value) || exactRating.length) : true
	const showLanguagesFilters = previewFilterCounts ? !!(Object.values(previewFilterCounts.languages).some((value) => value) || languageIDs.length) : true

	useEffect(() => {
		// reinitialize internal filter values when submitted values changes to keep them in sync
		// be careful with dependencies, non primitive values must be memoized, otherwise internal filter values would reset even when the submitted values haven't changed
		reset({
			serviceTotalPriceRange: {
				priceFrom: submittedServiceTotalPriceFrom,
				priceTo: submittedServiceTotalPriceTo
			},
			hasAvailableReservationSystem: submittedHasAvailableReservationSystem,
			openingHours: submittedOpeningHours,
			exactRating: submittedExactRating,
			cosmeticIDs: submittedCosmeticIDs,
			languageIDs: submittedLanguageIDs,
			orderBy: submittedOrderBy,
			availableReservations: {
				timeSlot: submittedAvResTimeSlotDate,
				dateFrom: submittedAvResTimeSlotDateFrom,
				dateTo: submittedAvResTimeSlotDateTo
			}
		})
	}, [
		submittedServiceTotalPriceFrom,
		submittedServiceTotalPriceTo,
		submittedHasAvailableReservationSystem,
		submittedOpeningHours,
		submittedExactRating,
		submittedCosmeticIDs,
		submittedLanguageIDs,
		submittedOrderBy,
		submittedAvResTimeSlotDate,
		submittedAvResTimeSlotDateFrom,
		submittedAvResTimeSlotDateTo,
		reset
	])

	useEffect(() => {
		setPreviewFilterCounts(filterCounts)
	}, [filterCounts])

	const onCollapseChange = (key: React.Key | React.Key[]) => {
		if (Array.isArray(key)) {
			setActiveFilter([...key])
		} else {
			setActiveFilter([key])
		}
	}

	const openFilterSidebarDetail = (detailType: SALONS_FILTER_TYPE) => {
		setFilterSidebarDetailOpen(true)
		setFilterDetailOpenedDirectly(false)
		setFilterSidebarDetailType(detailType)
	}

	const closeFilterSidebar = () => {
		setFilterSidebarOpen(false)
		if (isDirty) {
			reset()
		}
	}

	const handleSubmitFilter = (formValues: FilterValuesType) => {
		setFilterSidebarOpen(false)

		const params: LoadSalonsFromFiltersParams = {
			serviceTotalPriceFrom:
				formValues.serviceTotalPriceRange.priceFrom === serviceTotalPriceFromDefaultValue ? null : formValues.serviceTotalPriceRange.priceFrom,
			serviceTotalPriceTo:
				formValues.serviceTotalPriceRange.priceTo === serviceTotalPriceToDefaultValue ? null : formValues.serviceTotalPriceRange.priceTo,
			hasAvailableReservationSystem: formValues.hasAvailableReservationSystem,
			openingHoursStatus: formValues.openingHours ? OPENING_HOURS_STATUS.OPEN : OPENING_HOURS_STATUS.ALL,
			exactRating: formValues.exactRating,
			cosmeticIDs: formValues.cosmeticIDs,
			languageIDs: formValues.languageIDs,
			orderBy: formValues.orderBy,
			avResTimeSlotDate: formValues.availableReservations.timeSlot,
			avResTimeSlotDateFrom: formValues.availableReservations.dateFrom ? formValues.availableReservations.dateFrom.format(DEFAULT_DATE_FORMAT) : null,
			avResTimeSlotDateTo: formValues.availableReservations.dateTo ? formValues.availableReservations.dateTo.format(DEFAULT_DATE_FORMAT) : null
		}

		loadSalonsFromFilters(params)
	}

	const getCurrentSalonsFilterCounts = useCallback(
		async (filterValuesForCounts: FilterValuesType) => {
			if (!currencyCode) {
				setPreviewFilterCounts(undefined)
				return
			}

			setLoadingFilterCounts(true)
			try {
				const categoryIDs = getCategoryIDs(query.categoryIDs, topLevelCategoryID)

				const filterCountParams: GetSalonsFilterCountsParams = {
					serviceTotalPriceFrom:
						filterValuesForCounts.serviceTotalPriceRange.priceFrom === serviceTotalPriceFromDefaultValue
							? undefined
							: filterValuesForCounts.serviceTotalPriceRange.priceFrom,
					serviceTotalPriceTo:
						filterValuesForCounts.serviceTotalPriceRange.priceTo === serviceTotalPriceToDefaultValue
							? undefined
							: filterValuesForCounts.serviceTotalPriceRange.priceTo,
					hasAvailableReservationSystem: filterValuesForCounts.hasAvailableReservationSystem ? true : undefined,
					openingHoursStatus: filterValuesForCounts.openingHours ? OPENING_HOURS_STATUS.OPEN : OPENING_HOURS_STATUS.ALL,
					exactRating: filterValuesForCounts.exactRating,
					cosmeticIDs: filterValuesForCounts.cosmeticIDs,
					languageIDs: filterValuesForCounts.languageIDs,
					orderBy: filterValuesForCounts.orderBy,
					serviceTotalPriceCurrencyCode: currencyCode,
					categoryIDs,
					latMy: query.latMy,
					lonMy: query.lonMy
				}

				const newCountData = await apiBrowser.getSalonsFilterCounts(filterCountParams)
				setPreviewFilterCounts(newCountData)
			} catch {
				setPreviewFilterCounts(undefined)
			} finally {
				setLoadingFilterCounts(false)
			}
		},
		[
			apiBrowser,
			currencyCode,
			query.latMy,
			query.lonMy,
			serviceTotalPriceFromDefaultValue,
			serviceTotalPriceToDefaultValue,
			topLevelCategoryID,
			query.categoryIDs
		]
	)

	const getCurrentSalonsFilterCountsDebounced = useCallback(
		debounce((values: Partial<FilterValuesType>) => {
			const newValues: FilterValuesType = { ...getValues(), ...values }
			getCurrentSalonsFilterCounts(newValues)
		}, FILTER_COUNTS_CHANGE_DEBOUNCE_TIME_MS),
		[]
	)

	const setValuesAndGetCounts = (values: Partial<FilterValuesType>) => {
		Object.entries(values).forEach(([name, value]) => setValue(name as keyof FilterValuesType, value, { shouldDirty: true }))
		const newValues: FilterValuesType = { ...getValues(), ...values }
		getCurrentSalonsFilterCounts(newValues)
	}

	// reset filters do detail handlers
	const resetPriceFilterToDefault = () => {
		setValuesAndGetCounts({
			serviceTotalPriceRange: {
				priceFrom: serviceTotalPriceFromDefaultValue,
				priceTo: serviceTotalPriceToDefaultValue
			}
		})
	}

	const resetAvailableRSFilterToDefault = () => {
		setValuesAndGetCounts({
			hasAvailableReservationSystem: availableRSDefaultValue
		})
	}

	const resetOpeningHoursFilterToDefault = () => {
		setValuesAndGetCounts({
			openingHours: openingHoursDefaultValue
		})
	}

	const resetRatingFilterToDefault = () => {
		setValuesAndGetCounts({
			exactRating: ratingDefaultValue
		})
	}

	const resetCosmeticsFilterToDefault = () => {
		setValuesAndGetCounts({
			cosmeticIDs: cosmeticsDefaultValue
		})
	}

	const resetDateFilterToDefault = () => {
		setValuesAndGetCounts({
			availableReservations: {
				timeSlot: avResTimeSlotDateDefaultValue,
				dateFrom: avResTimeSlotDateFromDefaultValue,
				dateTo: avResTimeSlotDateToDefaultValue
			}
		})
	}

	const resetLanguagesFilterToDefault = () => {
		setValuesAndGetCounts({
			languageIDs: languagesDefaultValue
		})
	}

	const resetSortingFilterToDefault = () => {
		setValuesAndGetCounts({
			orderBy: orderByDefaultValue
		})
	}

	const resetFilterToDefault = () => {
		setValuesAndGetCounts({ ...defaultFilterValues })
	}

	// footer slider tags
	const getFooterSliderItems = () => {
		if (filterDetailOpenedDirectly) {
			return []
		}

		const tags: React.ReactNode[] = []

		// price tag
		const selectedPriceTitle = filterItemsConfig[SALONS_FILTER_TYPE.PRICE].getSelectedTitle(
			currencySymbol,
			serviceTotalPriceFromDefaultValue,
			serviceTotalPriceToDefaultValue,
			serviceTotalPriceFrom,
			serviceTotalPriceTo
		)

		if (selectedPriceTitle && !isPriceFilterEqualToDefault) {
			tags.push(<Tag key={SALONS_FILTER_TYPE.PRICE} label={selectedPriceTitle} onCloseBtnClick={resetPriceFilterToDefault} />)
		}

		// available RS Tag
		const selectedAvailableRSTitle = filterItemsConfig[SALONS_FILTER_TYPE.AVAILABLE_RS].getSelectedTitle(hasAvailableReservationSystem)
		if (selectedAvailableRSTitle) {
			tags.push(<Tag key={SALONS_FILTER_TYPE.AVAILABLE_RS} label={selectedAvailableRSTitle} onCloseBtnClick={resetAvailableRSFilterToDefault} />)
		}

		// opening hours tag
		const selectedOpeningHoursTitle = filterItemsConfig[SALONS_FILTER_TYPE.OPENING_HOURS].getSelectedTitle(openingHours)
		if (selectedOpeningHoursTitle) {
			tags.push(<Tag key={SALONS_FILTER_TYPE.OPENING_HOURS} label={selectedOpeningHoursTitle} onCloseBtnClick={resetOpeningHoursFilterToDefault} />)
		}

		// exactRating tag
		const selectedRatingTitle = filterItemsConfig[SALONS_FILTER_TYPE.RATING].getSelectedTitle(exactRating)
		if (selectedRatingTitle) {
			tags.push(<Tag key={SALONS_FILTER_TYPE.RATING} label={selectedRatingTitle} onCloseBtnClick={resetRatingFilterToDefault} />)
		}

		// cosmetics tag
		const cosmeticIDsTitle = filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].getSelectedTitle(initialCosmetics, cosmeticIDs)
		if (cosmeticIDsTitle.label) {
			tags.push(
				<Tag
					key={SALONS_FILTER_TYPE.COSMETICS}
					label={cosmeticIDsTitle.label}
					count={cosmeticIDsTitle.count > 1 ? cosmeticIDsTitle.count : undefined}
					onCloseBtnClick={resetCosmeticsFilterToDefault}
				/>
			)
		}

		// date filter tag
		const selectedDateTitle = filterItemsConfig[SALONS_FILTER_TYPE.DATE].getSelectedTitle(avResTimeSlotDate, avResTimeSlotDateFrom, avResTimeSlotDateTo)
		if (selectedDateTitle && !isDateFilterEqualToDefault) {
			tags.push(<Tag key={SALONS_FILTER_TYPE.DATE} label={selectedDateTitle} onCloseBtnClick={resetDateFilterToDefault} />)
		}

		// languages tag
		const languageIDsTitle = filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].getSelectedTitle(initialLanguages, languageIDs)

		if (languageIDsTitle.label) {
			tags.push(
				<Tag
					key={SALONS_FILTER_TYPE.LANGUAGES}
					label={languageIDsTitle.label}
					count={languageIDsTitle.count > 1 ? languageIDsTitle.count : undefined}
					onCloseBtnClick={resetLanguagesFilterToDefault}
				/>
			)
		}

		// sorting tag
		const selectedSortingTitle = filterItemsConfig[SALONS_FILTER_TYPE.SORTING].getSelectedTitle(sortingOptions, orderBy, SORTING_OPTION.RECOMMENDED)
		if (selectedSortingTitle && !isOrderByFilterEqualToDefault) {
			tags.push(<Tag key={SALONS_FILTER_TYPE.SORTING} label={selectedSortingTitle} onCloseBtnClick={resetSortingFilterToDefault} />)
		}

		return tags.length ? tags : null
	}

	const commonSidebarDetailProps = {
		filterItemsConfig,
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		setFilterValues: setValuesAndGetCounts
	}

	return (
		<>
			{!filterDetailOpenedDirectly && (
				<Drawer
					open={filterSidebarOpen}
					afterOpenChange={(open) => {
						if (!open) {
							setActiveFilter(defaultFilterKeys)
						}
					}}
					mask
					maskClosable
					onClose={closeFilterSidebar}
					title={messages?.Filters}
					headerExtra={!isFormEqualToDefault ? <SC.HeaderResetButton onClick={resetFilterToDefault}>{messages?.Reset}</SC.HeaderResetButton> : null}
					footer={
						<SidebarFooter
							isVisible={isDirty}
							extraContent={getFooterSliderItems()}
							loading={loadingFilterCounts}
							formId={FORM.FILTER_SIDEBAR}
							label={
								<>
									{messages?.Show} {previewFilterCounts?.count}
								</>
							}
						/>
					}
					destroyOnClose
				>
					<SC.SidebarContentWrapper>
						<SC.SidebarForm onSubmit={handleSubmit(handleSubmitFilter)} id={FORM.FILTER_SIDEBAR}>
							<Collapse
								activeKey={activeFilter}
								onChange={onCollapseChange}
								items={[
									{
										key: SALONS_FILTER_TYPE.PRICE,
										showArrow: true,
										label: filterItemsConfig[SALONS_FILTER_TYPE.PRICE].title,
										children: (
											<SC.PriceFilterWrapper>
												<HookFormField
													control={control}
													name={'serviceTotalPriceRange'}
													component={PriceRangeFormField}
													priceFromDefaultValue={serviceTotalPriceFromDefaultValue}
													priceToDefaultValue={serviceTotalPriceToDefaultValue}
													currencySymbol={currencySymbol}
													onAfterChange={(newValue: FilterValuesType['serviceTotalPriceRange']) =>
														getCurrentSalonsFilterCountsDebounced({ serviceTotalPriceRange: newValue })
													}
												/>
											</SC.PriceFilterWrapper>
										)
									},
									{
										key: SALONS_FILTER_TYPE.AVAILABLE_RS,
										showArrow: false,
										className: 'collapse-item-custom-header',
										label: (
											<HookFormField
												control={control}
												name={'hasAvailableReservationSystem'}
												component={SwitchField}
												label={filterItemsConfig[SALONS_FILTER_TYPE.AVAILABLE_RS].title}
												onAfterChange={(newValue: FilterValuesType['hasAvailableReservationSystem']) =>
													getCurrentSalonsFilterCountsDebounced({ hasAvailableReservationSystem: newValue })
												}
											/>
										),
										collapsible: 'disabled' as CollapsibleType
									},
									{
										key: SALONS_FILTER_TYPE.OPENING_HOURS,
										showArrow: false,
										className: 'collapse-item-custom-header',
										label: (
											<HookFormField
												control={control}
												name={'openingHours'}
												component={SwitchField}
												label={filterItemsConfig[SALONS_FILTER_TYPE.OPENING_HOURS].title}
												onAfterChange={(newValue: FilterValuesType['openingHours']) =>
													getCurrentSalonsFilterCountsDebounced({ openingHours: newValue })
												}
											/>
										),
										collapsible: 'disabled' as CollapsibleType
									},
									{
										key: SALONS_FILTER_TYPE.RATING,
										showArrow: true,
										label: filterItemsConfig[SALONS_FILTER_TYPE.RATING].title,
										children: (
											<SC.RatingFilterWrapper>
												<HookFormField
													control={control}
													name={'exactRating'}
													component={CheckboxGroupFormField<FilterValuesType['exactRating'][0]>}
													options={EXACT_RATING_OPTIONS(filterCounts?.exactRating, exactRating)}
													onAfterChange={(newValue: FilterValuesType['exactRating']) =>
														getCurrentSalonsFilterCountsDebounced({ exactRating: newValue })
													}
												/>
											</SC.RatingFilterWrapper>
										)
									},
									{
										key: SALONS_FILTER_TYPE.COSMETICS,
										showArrow: true,
										label: filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].title,
										className: 'collapse-item-not-collapsible',
										onClick: () => {
											openFilterSidebarDetail(SALONS_FILTER_TYPE.COSMETICS)
										},
										onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
											if (e.key === 'Enter') {
												openFilterSidebarDetail(SALONS_FILTER_TYPE.COSMETICS)
											}
										}
									},
									{
										key: SALONS_FILTER_TYPE.DATE,
										showArrow: true,
										label: filterItemsConfig[SALONS_FILTER_TYPE.DATE].title,
										className: 'collapse-item-not-collapsible',
										onClick: () => {
											openFilterSidebarDetail(SALONS_FILTER_TYPE.DATE)
										},
										onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
											if (e.key === 'Enter') {
												openFilterSidebarDetail(SALONS_FILTER_TYPE.DATE)
											}
										}
									},
									{
										key: SALONS_FILTER_TYPE.LANGUAGES,
										showArrow: true,
										label: filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].title,
										className: 'collapse-item-not-collapsible',
										onClick: () => {
											openFilterSidebarDetail(SALONS_FILTER_TYPE.LANGUAGES)
										},
										onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
											if (e.key === 'Enter') {
												openFilterSidebarDetail(SALONS_FILTER_TYPE.LANGUAGES)
											}
										}
									},
									{
										key: SALONS_FILTER_TYPE.SORTING,
										showArrow: true,
										label: filterItemsConfig[SALONS_FILTER_TYPE.SORTING].title,
										children: (
											<SC.SortingFilterWrapper>
												<HookFormField
													control={control}
													name={'orderBy'}
													component={RadioButtonsFormField<FilterValuesType['orderBy']>}
													options={sortingOptions as CustomDropdownOption<SORTING_OPTION>[]}
													onAfterChange={(newValue: FilterValuesType['orderBy']) =>
														getCurrentSalonsFilterCountsDebounced({ orderBy: newValue as SORTING_OPTION })
													}
												/>
											</SC.SortingFilterWrapper>
										)
									}
								].filter((salonFilter) => {
									if (salonFilter.key === SALONS_FILTER_TYPE.COSMETICS) {
										return showCosmeticsFilters
									}

									if (salonFilter.key === SALONS_FILTER_TYPE.RATING) {
										return showRatingsFilters
									}

									if (salonFilter.key === SALONS_FILTER_TYPE.LANGUAGES) {
										return showLanguagesFilters
									}

									return salonFilter
								})}
							/>
						</SC.SidebarForm>
					</SC.SidebarContentWrapper>
				</Drawer>
			)}
			{filterSidebarDetailType === SALONS_FILTER_TYPE.PRICE && (
				<FilterSidebarPrice {...commonSidebarDetailProps} submittedFilterValues={filterValues} currencySymbol={currencySymbol} />
			)}
			{filterSidebarDetailType === SALONS_FILTER_TYPE.RATING && (
				<FilterSidebarExactRating {...commonSidebarDetailProps} submittedFilterValues={filterValues} ratingCounts={previewFilterCounts?.exactRating} />
			)}

			{filterSidebarDetailType === SALONS_FILTER_TYPE.COSMETICS && (
				<FilterSidebarCosmetics
					{...commonSidebarDetailProps}
					submittedFilterValues={filterValues}
					initialClientDataLoading={initialClientDataLoading}
					initialCosmetics={initialCosmetics}
					cosmeticCounts={previewFilterCounts?.cosmetics}
				/>
			)}
			{filterSidebarDetailType === SALONS_FILTER_TYPE.DATE && (
				<FilterSidebarDate
					{...commonSidebarDetailProps}
					submittedFilterValues={filterValues}
					assetsPath={assetsPath}
					query={query}
					currencyCode={currencyCode}
					topLevelCategoryID={topLevelCategoryID}
					maxAvResTimeSlotDateRange={maxAvResTimeSlotDateRange}
				/>
			)}
			{filterSidebarDetailType === SALONS_FILTER_TYPE.LANGUAGES && (
				<FilterSidebarLanguages
					{...commonSidebarDetailProps}
					submittedFilterValues={filterValues}
					initialClientDataLoading={initialClientDataLoading}
					languages={initialLanguages}
					languagesCounts={previewFilterCounts?.languages}
				/>
			)}
			{filterSidebarDetailType === SALONS_FILTER_TYPE.SORTING && (
				<FilterSidebarOrderBy {...commonSidebarDetailProps} submittedFilterValues={filterValues} sortingOptions={sortingOptions} />
			)}
		</>
	)
}

export default FilterSidebar
