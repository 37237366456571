/* eslint-disable global-require */
import { Button, Heading, Select, theme } from '@notino/react-styleguide'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import styled, { css } from 'styled-components'

// utils
import { BREAKPOINTS, SALONS_PAGE_MOBILE_BREAKPOINT, SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import { NotinoLabelRegular400, resetButtonStyles } from '../../../../styles/helpers'

// styles
import * as SharedSC from '../SharedStyles'

const CONTROL_TAB_HEIGHT = 50
const CONTROL_TAB_MARGIN = 20

export const Grid = styled.div`
	flex: 1;
	min-width: 0; // important so the Grid does not stretch when children overflow
`

export const SalonsGridView = styled.div`
	display: grid;
	gap: 40px 28px;
	grid-template-columns: 1fr 1fr 1fr;

	@media (max-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: ${BREAKPOINTS.sm}) {
		grid-template-columns: 1fr;
		gap: 30px 28px;
	}
`

export const BottomNavigation = styled.div`
	position: relative;
	margin-top: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 48px;
`

export const PaginationWrapper = styled.div`
	position: absolute;
	right: 0;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const SortingAndPaginationDesktop = styled.div`
	display: none;
	justify-content: space-between;
	align-items: center;
	height: ${CONTROL_TAB_HEIGHT}px;
	margin: ${CONTROL_TAB_MARGIN}px 0;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: flex;
	}
`

export const SortingMobile = styled.div`
	margin-top: 6px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const SubcategoriesSlider = styled(OverlayScrollbarsComponent)`
	scroll-behavior: smooth;
	margin-top: 16px;
	overflow: auto;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 40px;
	}
`

export const SubcategoriesSliderContent = styled.div`
	white-space: nowrap;
	padding: 4px 0;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		padding: 8px 0;
	}
`

export const ChipsSlider = styled.div`
	display: flex;
	gap: 8px;
	white-space: nowrap;
	overflow: auto;
	padding-bottom: 4px;
	scroll-behavior: smooth;
	margin-top: 8px;
`

export const SubcategoryButton = styled(Button)`
	height: 32px;
	display: inline-flex;
	align-items: center;
	gap: 6px;
	font-size: 12px;
	border-color: #e1e1e1;

	&:not(:disabled) {
		&:hover,
		&:focus-visible {
			border-color: #e1e1e1;
		}
	}

	&:not(:last-child) {
		margin-right: 8px;
	}

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`

export const CategoryButtonIcon = styled.img`
	height: 15px;
	width: 15px;
	object-fit: contain;
`

export const MainTitle = styled(Heading.H1)`
	font-weight: 500;
	margin-top: 4px;
	margin-bottom: 16px;
	text-align: left !important; // override for notino global styles
`

export const NoSalonsMessageWrapper = styled.div`
	padding-top: 140px;
	padding-bottom: 140px;
	text-align: center;
`

export const NoSalonsMessage = styled.h3`
	font-weight: 300;
	font-size: 20px;
	margin-top: 32px;
	margin-bottom: 0px; // resetting global notino margin on heading
`

export const ToggleListContainerDesktop = styled.div`
	text-align: end;
	height: ${CONTROL_TAB_HEIGHT}px;
	display: flex;
	align-items: center;
	margin: ${CONTROL_TAB_MARGIN}px 0;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const ToggleMapContainerMobile = styled.div`
	position: sticky;
	bottom: 28px;
	margin-top: 40px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const ToggleMapButton = styled.button`
	white-space: nowrap;
	color: ${theme.color.text.primary};
	text-decoration: underline;
	border: none;
	background: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
`

export const ToggleMapButtonMobile = styled(SharedSC.ToggleButtonMobile)`
	position: relative;
	left: 50%;
	transform: translateX(-50%);
`

export const SalonsMapView = styled.div`
	position: relative;

	margin-top: 16px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 0;
	}
`

export const TopLevelCategoriesSlider = styled(OverlayScrollbarsComponent)`
	margin-top: 16px;
	overflow-x: auto;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 40px;
	}
`

export const TopLevelCategoriesSliderContent = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 24px;
	padding-bottom: 8px;
`

export const TopLevelCategoryTitle = styled.div`
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	color: #000 !important; // fix color for iphone

	/* line clamp */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`

export const TopLevelCategoryButton = styled.button`
	${resetButtonStyles};
	display: block;
`

export const TopLevelCategoryImgWrapper = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 999px;
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
`

export const TopLevelCategoryImg = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const MobileOnly = styled.div`
	display: none;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: block;
	}
`

// NOTE: notino neposkytuje lepsie moznosti stylovania Selectu, preto
// musime targetovat cez first-child, nth-child a sibling
export const SortingSelect = styled(Select)<{ $assetsPath: string }>`
	width: auto;

	// selector styles
	> div[role='combobox'] {
		flex-direction: row-reverse;

		// label styles
		> div:first-child {
			padding: 0.75rem 0.25rem;
			${NotinoLabelRegular400};
		}

		// arrow styles
		> div:nth-child(2) {
			padding: 0.75rem 0 0.75rem 1rem;

			svg {
				display: none;
			}

			${({ $assetsPath }) => css`
				&::after {
					content: url('${`${$assetsPath}/${require(`../../../../assets/icons/sort.svg`)}`}');
				}
			`}
		}
	}

	// dropdown styles
	> div[role='combobox'] ~ div {
		right: 0;
		left: unset;
	}

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		> div[role='combobox'] ~ div {
			left: 0;
			right: unset;
		}
	}
`

export const SalonsSearchWrapper = styled.div`
	width: 100%;
	max-width: 382px;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		max-width: 100%;
	}
`
