import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	justify-content: center;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		justify-content: flex-start;
	}
`

export const StoreBadgeImage = styled.img`
	max-height: 32px;
	width: auto !important; // reset global 100%
`

export const Text = styled.span`
	display: flex;
	align-items: center;
	${theme.typography.bodySmall}
	color: ${theme.color.text.tertiary};
`
