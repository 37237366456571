/* eslint-disable global-require */
import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { BREAKPOINTS, SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import {
	NotinoBodyMedium,
	NotinoBodySmall,
	NotinoLabelRegular500,
	NotinoTitleLarge,
	NotinoTitleSmall,
	resetButtonStyles,
	resetListStyles
} from '../../../../styles/helpers'

const NOTINO_MODAL_XS_PADDING = 20
const NOTINO_MODAL_SM_PADDING = 32

// NOTE: we need to override styles on Notino modal for which no better approach was found
export const ModalWrapper = styled.div`
	& div[role='dialog'] {
		position: relative;
		max-width: 600px;

		& > button {
			display: none;
		}

		@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
			max-width: 832px;
		}
	}
`

export const CloseButton = styled.button`
	${resetButtonStyles};
	position: absolute;

	top: 16px;
	right: 16px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		top: 24px;
		right: 24px;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: -${NOTINO_MODAL_XS_PADDING}px;
	margin-right: -${NOTINO_MODAL_XS_PADDING}px;
	margin-bottom: -${NOTINO_MODAL_XS_PADDING}px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		margin-left: -${NOTINO_MODAL_SM_PADDING}px;
		margin-right: -${NOTINO_MODAL_SM_PADDING}px;
		margin-bottom: -${NOTINO_MODAL_SM_PADDING}px;
	}

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		flex-direction: row;
	}
`

export const MobileImageWrapper = styled.div`
	aspect-ratio: 1.7;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const DesktopImageWrapper = styled.div`
	min-width: 396px;
	flex: 1;
	display: none;
	aspect-ratio: 0.72;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		display: block;
	}
`

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
`

export const TextContent = styled.div`
	flex: 1;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		padding: 56px 24px;
	}
`

export const Heading = styled.div`
	margin-bottom: 8px;

	${NotinoTitleSmall};

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoTitleLarge};
	}
`

export const Text = styled.div`
	color: #404040;
	${NotinoBodySmall};
	margin-bottom: 16px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyMedium};
		margin-bottom: 24px;
	}
`

export const List = styled.ul`
	${resetListStyles};

	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-bottom: 24px;
	}
`

export const ListItem = styled.li`
	color: #000;
	display: flex;
	align-items: center;
	gap: 8px;
	${NotinoBodySmall};
	margin: 0 !important; // Override Notino main app styles
	padding: 0 !important; // Override Notino main app styles

	&::before {
		display: none; // Override Notino main app styles
	}

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyMedium};
		margin-bottom: 0 0 16px 0 !important;
	}
`

export const IconWrapper = styled.div`
	background-color: ${theme.color.background.highlight};
	width: 32px;
	height: 32px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	color: #fff;
`

export const DownloadsSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		justify-content: space-between;
		flex-direction: row;
		align-items: flex-start;
		gap: 24px;
	}
`

export const StoreDownloadWrapper = styled.div``

export const QRCodeImageWrapper = styled.div`
	width: 92px;
	height: 92px;
	flex: 0 0 auto;
`

export const DownloadText = styled.div`
	${NotinoLabelRegular500};
	color: #000;
	margin-bottom: 8px;

	text-align: center;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		text-align: left;
	}
`
