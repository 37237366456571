import { IDropdownOption } from '@notino/react-styleguide'
import dayjs, { Dayjs } from 'dayjs'
import { OverlayScrollbarsComponentProps } from 'overlayscrollbars-react'
import { IntlFormatters } from 'react-intl'

// types
import {
	Phone,
	Price,
	SalonServicesResponse,
	ConfigResponse,
	Cosmetic,
	Language,
	SalonsPageQueryType,
	GetSalonsFilterCountResponse,
	AvailableRsTimeSlotsResponse
} from '../types/types'

// enums
import {
	DAYNAME_DATE_FORMAT,
	PDF_FILE_TYPE,
	RESERVATIONS_TIME_SLOTS,
	SALONS_FILTER_TYPE,
	SORTING_OPTION,
	DAYNAME_DATE_YEAR_FORMAT,
	OPENING_HOURS_STATUS,
	RATINGS,
	ARRAY_QUERY_PARAM_MAX_LENGTH
} from './enums'

// regex
import { uuidRegex } from './regex'

const getCountryByCode = (countries: ConfigResponse['rolloutCountries'], code: string): ConfigResponse['rolloutCountries'][0] | undefined => {
	return countries.find((country) => country.code === code)
}

/**
 * Get phone number
 * @param phone IPhone
 * @returns {string}
 */
export const getPhoneNumber = (phone?: Phone, countries?: ConfigResponse['rolloutCountries']): string => {
	if (!phone || !countries || countries?.length < 0) {
		return ''
	}
	const country = getCountryByCode(countries, phone.phonePrefixCountryCode)
	const result = `${country?.phonePrefix}${phone.phone}`
	return result
}

/**
 * Get first level categories
 * @param {categories} ISalonServicesResponse
 * @returns {string[]}
 */
export const getFirstLevelCategoriesNames = (categories?: SalonServicesResponse): string[] => {
	if (!categories) {
		return []
	}
	const result: string[] = []
	categories?.groupedServicesByCategory?.forEach((obj) => obj.category?.name && result.push(obj.category?.name))
	return result
}

export const repeat = (textToRepeat: string, numberOfTimesToRepeat: number): string => {
	let result = ''
	for (let i = 0; i < numberOfTimesToRepeat; i += 1) {
		result = `${result}${textToRepeat}`
	}
	return result
}

/**
 * Transforms normalized form to number
 * @param {Price | null} [price]
 * @returns {number}
 */
export const decodePrice = (price: Price | null | undefined): number | null | undefined => {
	if (!price) {
		return null
	}

	const { exponent, significand } = price

	// Calculate the decimal places from the exponent
	const decimalPlaces = Math.abs(exponent)

	// Calculate the result
	const result = significand * 10 ** exponent

	// Use toFixed() to format the number with the calculated decimal places
	const formattedResult = result.toFixed(decimalPlaces)

	// Convert the formatted result back to a number
	const numberResult = parseFloat(formattedResult)

	return numberResult
}

/**
 * Check if is pdf file or not
 * @param {fileUrl | null | undefined} [string]
 * @returns {string | undefined }
 */
export const isFilePDF = (fileUrl?: string): string | undefined => {
	if (!fileUrl) {
		return undefined
	}

	return fileUrl.endsWith('.pdf') ? PDF_FILE_TYPE : undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeQueryParams = (queryParams: any) =>
	Object.keys(queryParams)?.map((queryParam) => {
		if (queryParam === '' || queryParam === undefined || queryParam === null) {
			return undefined
		}
		return queryParam
	})

export const getCurrentPage = (page: number): number => (!page || Number.isNaN(page) ? 1 : page)

export const getCategoryIDs = (subCategoriesIDs: string[], topLevelCategoryID: string | undefined) => {
	let categoryIDs: string[] | undefined
	if (subCategoriesIDs && subCategoriesIDs.length > 0) {
		categoryIDs = subCategoriesIDs
	} else if (topLevelCategoryID) {
		categoryIDs = [topLevelCategoryID]
	}
	return categoryIDs
}

export const isElementOverflownX = ({ clientWidth, scrollWidth }: { clientWidth: number; scrollWidth: number }) => {
	return scrollWidth > clientWidth
}

export const toHoursAndMinutes = (totalMinutes?: number) => {
	if (!totalMinutes) {
		return ''
	}
	const hours = Math.floor(totalMinutes / 60)
	const minutes = totalMinutes % 60
	return `${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? ` ${minutes}m` : ''}`
}

export const getGoogleMapsLink = ({ googleMapsUrl, lat, lon }: { googleMapsUrl: string; lat: number | undefined; lon: number | undefined }) => {
	return lat && lon ? `${googleMapsUrl}/?q=${lat},${lon}` : undefined
}

export const formatNumberValueOnChange = (value: string) => {
	return value.replace(/[^0-9,.]/g, '')
}

export const formatNumber = (value?: string | number) => {
	if (Number.isNaN(value)) return '' // Handle empty input

	let formattedValue = String(value)

	// Remove leading zero numbers, except when it's just a single zero
	if (formattedValue !== '0' && !/^0[.,]/.test(formattedValue)) {
		formattedValue = formattedValue.replace(/^0+/, '')
	}

	// Remove any non-numeric characters
	formattedValue = formattedValue.replace(/[^0-9,.]/g, '')

	// Replace periods with commas for the decimal separator
	formattedValue = formattedValue.replace(/\./g, ',')

	// Split the value into integer and decimal parts
	const [integerPart, decimalPart] = formattedValue.split(',')

	// Add thousands separators to the integer part
	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	// Ensure the decimal part has at most two decimal places
	const formattedDecimalPart = decimalPart ? `,${decimalPart.slice(0, 2)}` : ''

	return formattedIntegerPart + formattedDecimalPart
}

export const parseTextValueToNumberValue = (formattedValue: string) => {
	if (!formattedValue) return NaN // Handle empty input

	// Remove any non-numeric characters, except for periods
	const numericString = formattedValue.replace(/[^0-9]/g, '')

	// Use parseFloat to convert the string to a floating-point number
	return parseFloat(numericString)
}

export const TIME_SLOTS_TRANSLATIONS = (formatMessage: IntlFormatters['formatMessage']): Record<RESERVATIONS_TIME_SLOTS, string | undefined> => ({
	[RESERVATIONS_TIME_SLOTS.ANY]: formatMessage({ id: 'Anytime', defaultMessage: 'Anytime' }),
	[RESERVATIONS_TIME_SLOTS.MORNING]: formatMessage({ id: 'Morning', defaultMessage: 'Morning' }),
	[RESERVATIONS_TIME_SLOTS.AFTERNOON]: formatMessage({ id: 'Afternoon', defaultMessage: 'Afternoon' }),
	[RESERVATIONS_TIME_SLOTS.EVENING]: formatMessage({ id: 'Evening', defaultMessage: 'Evening' })
})

export const SALONS_FILTER_ITEMS_CONFIG = (formatMessage: IntlFormatters['formatMessage'], priceMin: number | undefined, priceMax: number | undefined) => ({
	[SALONS_FILTER_TYPE.PRICE]: {
		defaultValue: [priceMin, priceMax],
		title: formatMessage({ id: 'Price' }),
		getSelectedTitle: (
			currencySymbol: string | undefined,
			minPrice: number | undefined,
			maxPrice: number | undefined,
			priceFrom: number | undefined,
			priceTo: number | undefined
		) => {
			const missingBoundaryRanges = minPrice === undefined || maxPrice === undefined
			const missingSelectedPrices = Number.isNaN(priceFrom) || Number.isNaN(priceTo)
			const notChangedRanges = minPrice === priceFrom && maxPrice === priceTo

			if (missingBoundaryRanges || notChangedRanges || missingSelectedPrices || !currencySymbol) {
				return null
			}

			if (minPrice === priceFrom && maxPrice !== priceTo) {
				return formatMessage({ id: 'to { priceTo }', defaultMessage: 'to { priceTo }' }, { priceTo: `${formatNumber(priceTo)}${currencySymbol}` })
			}

			if (maxPrice === priceTo && minPrice !== priceFrom) {
				return formatMessage(
					{ id: 'from { priceFrom }', defaultMessage: 'from { priceFrom }' },
					{ priceFrom: `${formatNumber(priceFrom)}${currencySymbol}` }
				)
			}

			return formatMessage(
				{ id: 'from { priceFrom } to { priceTo }', defaultMessage: 'from { priceFrom } to { priceTo }' },
				{ priceFrom: `${formatNumber(priceFrom)}${currencySymbol}`, priceTo: `${formatNumber(priceTo)}${currencySymbol}` }
			)
		}
	},
	[SALONS_FILTER_TYPE.AVAILABLE_RS]: {
		defaultValue: false,
		title: formatMessage({ id: 'Available for online reservations', defaultMessage: 'Available for online reservations' }),
		getSelectedTitle: (available?: boolean) => {
			return available ? formatMessage({ id: 'Available for online reservations', defaultMessage: 'Available for online reservations' }) : null
		}
	},
	[SALONS_FILTER_TYPE.OPENING_HOURS]: {
		defaultValue: false,
		title: formatMessage({ id: 'Is open', defaultMessage: 'Is open' }),
		getSelectedTitle: (open?: boolean) => {
			return open ? formatMessage({ id: 'Is open', defaultMessage: 'Is open' }) : null
		}
	},
	[SALONS_FILTER_TYPE.RATING]: {
		defaultValue: [],
		title: formatMessage({ id: 'Rating', defaultMessage: 'Rating' }),
		getSelectedTitle: (selectedRatings?: number[]) => {
			if (!selectedRatings?.length) {
				return null
			}
			const formattedRatings = selectedRatings.map((rating) => `${rating}*`)
			return `${formatMessage({ id: 'Rating', defaultMessage: 'Rating' })} ${formattedRatings.join(', ')}`
		}
	},
	[SALONS_FILTER_TYPE.COSMETICS]: {
		defaultValue: [],
		title: formatMessage({ id: 'Cosmetics they use', defaultMessage: 'Cosmetics they use' }),
		getSelectedTitle: (
			cosmetics: Cosmetic[],
			selectedCosmetics: string[]
		): {
			label: string | null
			count: number
		} => {
			if (selectedCosmetics.length === 0) {
				return { label: null, count: 0 }
			}

			const defaultLabel = formatMessage({ id: 'Cosmetics', defaultMessage: 'Cosmetics' })

			if (selectedCosmetics.length === 1) {
				return { label: cosmetics.find((cosmetic) => cosmetic.id === selectedCosmetics[0])?.name || defaultLabel, count: 0 }
			}

			const validCosmetics = selectedCosmetics.filter((selectedCosmeticId) => cosmetics.find((cosmetic) => cosmetic.id === selectedCosmeticId))

			return {
				label: defaultLabel,
				count: validCosmetics.length
			}
		}
	},
	[SALONS_FILTER_TYPE.DATE]: {
		defaultValue: {
			avResTimeSlotDate: RESERVATIONS_TIME_SLOTS.ANY,
			avResTimeSlotDateFrom: undefined,
			avResTimeSlotDateTo: undefined
		},
		title: formatMessage({ id: 'Date', defaultMessage: 'Date' }),
		getSelectedTitle: (avResTimeSlotDate: RESERVATIONS_TIME_SLOTS, avResTimeSlotDateFrom?: Dayjs, avResTimeSlotDateTo?: Dayjs) => {
			const timeSlotTranslation = avResTimeSlotDate !== RESERVATIONS_TIME_SLOTS.ANY ? TIME_SLOTS_TRANSLATIONS(formatMessage)[avResTimeSlotDate] || '' : ''

			if (!avResTimeSlotDateFrom && !avResTimeSlotDateTo) {
				return timeSlotTranslation
			}

			const formattedPeriod = timeSlotTranslation ? `, ${timeSlotTranslation}` : ''

			let formatFrom = DAYNAME_DATE_FORMAT

			if (avResTimeSlotDateFrom && !avResTimeSlotDateTo) {
				if (avResTimeSlotDateFrom.isSame(dayjs(), 'date')) {
					return `${formatMessage({ id: 'Today', defaultMessage: 'Today' })}${formattedPeriod}`
				}

				if (avResTimeSlotDateFrom.year() !== dayjs().year()) {
					formatFrom = DAYNAME_DATE_YEAR_FORMAT
				}
				return `${dayjs(avResTimeSlotDateFrom).format(formatFrom)}${formattedPeriod}`
			}

			if (avResTimeSlotDateTo) {
				let formatTo = DAYNAME_DATE_FORMAT

				if (avResTimeSlotDateTo.year() !== dayjs().year()) {
					formatTo = DAYNAME_DATE_YEAR_FORMAT
				}

				return `${dayjs(avResTimeSlotDateFrom).format(formatFrom)} - ${dayjs(avResTimeSlotDateTo).format(formatTo)}${formattedPeriod}`
			}
			return ''
		}
	},
	[SALONS_FILTER_TYPE.LANGUAGES]: {
		defaultValue: [],
		title: formatMessage({ id: 'Languages they speak', defaultMessage: 'Languages they speak' }),
		getSelectedTitle: (
			languages: Language[],
			selectedLanguages: string[]
		): {
			label: string | null
			count: number
		} => {
			if (selectedLanguages.length === 0) {
				return { label: null, count: 0 }
			}

			const defaultLabel = formatMessage({ id: 'Language', defaultMessage: 'Language' })

			if (selectedLanguages.length === 1) {
				return { label: languages.find((lang) => lang.id === selectedLanguages[0])?.name || defaultLabel, count: 1 }
			}

			const validLanguages = selectedLanguages.filter((selectedLanguageId) => languages.find((language) => language.id === selectedLanguageId))

			return { label: defaultLabel, count: validLanguages.length }
		}
	},
	[SALONS_FILTER_TYPE.SORTING]: {
		defaultValue: SORTING_OPTION.RECOMMENDED,
		title: formatMessage({ id: 'Sorting', defaultMessage: 'Sorting' }),
		getSelectedTitle: (sortingOptions: IDropdownOption[], currentOption: SORTING_OPTION, defaultOption = SORTING_OPTION.RECOMMENDED) => {
			if (currentOption === defaultOption) {
				return null
			}

			return (
				sortingOptions.find((option) => {
					return option.id === currentOption
				})?.label || null
			)
		}
	}
})

// compares for equality only values, order of the elements doesn't matter
export const areValuesInArrayEqual = <T>(array1: T[], array2: T[]) => {
	if (array1.length !== array2.length) {
		return false
	}

	const sortedA = [...array1].sort()
	const sortedB = [...array2].sort()

	return sortedA.every((value, index) => value === sortedB[index])
}

export const isNil = <T>(value: T): boolean => {
	return value === undefined || value === null
}

export const SCROLLBAR_OPTIONS: OverlayScrollbarsComponentProps['options'] = {
	scrollbars: {
		autoHide: 'move',
		autoHideDelay: 1000
	}
}

export const isEnumValue = <T extends { [k: string]: string | number }>(checkValue: any, enumObject: T): checkValue is T[keyof T] =>
	(typeof checkValue === 'string' || typeof checkValue === 'number') && Object.values(enumObject).includes(checkValue)

export const getValidSalonsPageQuery = (query: SalonsPageQueryType) => {
	const validAvResTimeSlotDateFrom = query.avResTimeSlotDateFrom && dayjs(query.avResTimeSlotDateFrom).isValid() ? query.avResTimeSlotDateFrom : undefined
	const validAvResTimeSlotDateTo =
		validAvResTimeSlotDateFrom &&
		query.avResTimeSlotDateTo &&
		dayjs(query.avResTimeSlotDateTo).isValid() &&
		dayjs(query.avResTimeSlotDateTo).isAfter(dayjs(validAvResTimeSlotDateFrom))
			? query.avResTimeSlotDateTo
			: undefined
	const validAvResTimeSlotDate =
		query.avResTimeSlotDateTo && validAvResTimeSlotDateFrom && isEnumValue(query.avResTimeSlotDate, RESERVATIONS_TIME_SLOTS)
			? query.avResTimeSlotDate
			: undefined

	const validServiceTotalPriceFrom =
		query.serviceTotalPriceFrom !== undefined && query.avResTimeSlotDateFrom !== null && !Number.isNaN(query.serviceTotalPriceFrom)
			? query.serviceTotalPriceFrom
			: undefined

	let validServiceTotalPriceTo

	if (query.serviceTotalPriceTo !== undefined && query.serviceTotalPriceTo !== null && !Number.isNaN(query.serviceTotalPriceTo)) {
		if (validServiceTotalPriceFrom !== undefined && validServiceTotalPriceFrom < query.serviceTotalPriceTo) {
			validServiceTotalPriceTo = query.serviceTotalPriceTo
		} else if (validServiceTotalPriceFrom === undefined) {
			validServiceTotalPriceTo = query.serviceTotalPriceTo
		}
	}

	return {
		...query,
		page: query.page !== undefined && query.page !== null && !Number.isNaN(query.page) ? query.page : 1,
		openingHoursStatus: isEnumValue(query.openingHoursStatus, OPENING_HOURS_STATUS) ? query.openingHoursStatus : undefined,
		latMy: typeof query.latMy !== 'undefined' && typeof query.latMy !== null && !Number.isNaN(query.latMy) ? query.latMy : undefined,
		lonMy: typeof query.lonMy !== 'undefined' && typeof query.lonMy !== null && !Number.isNaN(query.lonMy) ? query.lonMy : undefined,
		googlePlaceID: typeof query.googlePlaceID === 'string' ? query.googlePlaceID : undefined,
		categoryIDs: Array.isArray(query.categoryIDs) ? query.categoryIDs.filter((item) => item.match(uuidRegex)) : [],
		languageIDs: Array.isArray(query.languageIDs) ? query.languageIDs.filter((item) => item.match(uuidRegex)).slice(0, ARRAY_QUERY_PARAM_MAX_LENGTH) : [], // limit max number of selected items
		cosmeticIDs: Array.isArray(query.cosmeticIDs) ? query.cosmeticIDs.filter((item) => item.match(uuidRegex)).slice(0, ARRAY_QUERY_PARAM_MAX_LENGTH) : [], // limit max number of selected items
		orderBy: isEnumValue(query.orderBy, SORTING_OPTION) ? query.orderBy : SORTING_OPTION.RECOMMENDED,
		exactRating: Array.isArray(query.exactRating) ? query.exactRating.filter((item) => isEnumValue(item, RATINGS)) : [],
		isMapView: typeof query.isMapView === 'boolean' ? query.isMapView : false,
		serviceTotalPriceFrom: validServiceTotalPriceFrom,
		serviceTotalPriceTo: validServiceTotalPriceTo,
		// NOTE: hasAvailableReservationSystem is by default true
		hasAvailableReservationSystem: typeof query.hasAvailableReservationSystem === 'boolean' ? query.hasAvailableReservationSystem : true,
		avResTimeSlotDate: validAvResTimeSlotDate,
		avResTimeSlotDateFrom: validAvResTimeSlotDateFrom,
		avResTimeSlotDateTo: validAvResTimeSlotDateTo
	}
}

export const getValidPriceRangeQueryParams = (
	serviceTotalPriceFrom: SalonsPageQueryType['serviceTotalPriceFrom'],
	serviceTotalPriceTo: SalonsPageQueryType['serviceTotalPriceTo'],
	priceRange?: GetSalonsFilterCountResponse['priceRange']
) => {
	let validServiceTotalPriceFrom: number | undefined

	if (serviceTotalPriceFrom !== undefined && serviceTotalPriceFrom !== null && !Number.isNaN(serviceTotalPriceFrom)) {
		if (priceRange) {
			if (serviceTotalPriceFrom >= priceRange.minValue && serviceTotalPriceFrom < priceRange.maxValue) {
				validServiceTotalPriceFrom = serviceTotalPriceFrom
			} else {
				validServiceTotalPriceFrom = priceRange.minValue
			}
		} else {
			validServiceTotalPriceFrom = serviceTotalPriceFrom
		}
	}

	let validServiceTotalPriceTo: number | undefined

	if (serviceTotalPriceTo !== undefined && serviceTotalPriceTo !== null && !Number.isNaN(serviceTotalPriceTo)) {
		if (priceRange) {
			if (validServiceTotalPriceFrom !== undefined) {
				if (serviceTotalPriceTo > validServiceTotalPriceFrom && serviceTotalPriceTo <= priceRange.maxValue) {
					validServiceTotalPriceTo = serviceTotalPriceTo
				} else {
					validServiceTotalPriceTo = priceRange.maxValue
				}
			} else if (serviceTotalPriceTo <= priceRange.maxValue && serviceTotalPriceTo > priceRange.minValue) {
				validServiceTotalPriceTo = serviceTotalPriceTo
			} else {
				validServiceTotalPriceTo = priceRange.maxValue
			}
		} else if (validServiceTotalPriceFrom !== undefined && validServiceTotalPriceFrom < serviceTotalPriceTo) {
			validServiceTotalPriceTo = serviceTotalPriceTo
		} else if (validServiceTotalPriceFrom === undefined) {
			validServiceTotalPriceTo = serviceTotalPriceTo
		}
	}

	return {
		serviceTotalPriceFrom: validServiceTotalPriceFrom,
		serviceTotalPriceTo: validServiceTotalPriceTo
	}
}

export const isTimeSlotAvailable = (timeSlotData: AvailableRsTimeSlotsResponse['dates'], date: Dayjs) => {
	return timeSlotData.find((data) => dayjs(data.date).isSame(date, 'date'))?.available
}

export const DATE_PICKER_INIT_DATE = dayjs()

/**
 * NOTE: edit of url slug according to SEO feedback:
 * "The URL does not end with a slash, it is not an error, but it is not consistent throughout the notino website.
 * A URL without a trailing slash should be redirected to a variant with a trailing slash, and the canonical and the link from the home page should be corrected accordingly."
 */
export const formatUrlSlug = (slug: string | null | undefined) => {
	if (!slug) {
		return ''
	}

	return slug.endsWith('/') ? slug : `${slug}/`
}

export const truncateString = (inputString: string | null | undefined, maxLength = 150): string => {
	if (!inputString) {
		return ''
	}

	if (inputString.length <= maxLength) {
		return inputString
	}

	const truncatedString = inputString.substring(0, maxLength - 3) // -3 to account for the three dots
	return `${truncatedString}...`
}
