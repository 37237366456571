import React, { useEffect, useRef } from 'react'
import RcDrawer from 'rc-drawer'

// utils
import motionProps from './motion'
import { DrawerProps } from './types'

// style
import * as SC from './DrawerStyles'
import { RC_DRAWER_IS_OPEN_CLASS_NAME } from '../../styles/constants'

// component
const Drawer = (props: DrawerProps) => {
	const { title, closeIcon = <SC.CloseIcon />, open, footer, children, onClose, headerExtra, afterOpenChange, ...restProps } = props

	/**
	 * When the rc-drawer component opens, it adds an inline style to the body: "width: calc(100% - 17px)".
	 * In the main Notino app, the body has a default "margin: 0 auto;" style, which causes the page to shrink
	 * when the drawer opens and expand again when it closes.
	 *
	 * To prevent this resizing, we need to override the body's margin style by adding RC_DRAWER_IS_OPEN_CLASS_NAME
	 * whenever rc-drawer is open.
	 * and remove it when the closing animation is done
	 *
	 * This class should be added to the body only when necessary and removed only when no other drawer (such as Filters) is open.
	 * Otherwise, closing one drawer would inadvertently remove the class needed for another open drawer.
	 */

	const isAnotherSidebarAlreadyOpen = useRef(false)

	const afterOpenChangeWrap = (isOpen: boolean) => {
		if (!isOpen && !isAnotherSidebarAlreadyOpen.current) {
			document.body.classList.remove(RC_DRAWER_IS_OPEN_CLASS_NAME)
		}

		if (afterOpenChange) {
			afterOpenChange(isOpen)
		}
	}

	useEffect(() => {
		if (open) {
			if (document.body.classList.contains(RC_DRAWER_IS_OPEN_CLASS_NAME)) {
				isAnotherSidebarAlreadyOpen.current = true
				return
			}

			document.body.classList.add(RC_DRAWER_IS_OPEN_CLASS_NAME)
			isAnotherSidebarAlreadyOpen.current = false
		}
	}, [open])

	return (
		<RcDrawer open={open} onClose={onClose} width={420} afterOpenChange={afterOpenChangeWrap} {...restProps} {...motionProps}>
			<SC.DrawerBody>
				<SC.Header>
					<SC.CloseButton onClick={onClose}>{closeIcon}</SC.CloseButton>
					<SC.HeaderTitle>{title}</SC.HeaderTitle>
					{headerExtra && <SC.HeaderExtra>{headerExtra}</SC.HeaderExtra>}
				</SC.Header>
				<SC.Content>{children}</SC.Content>
				{footer && <SC.Footer>{footer}</SC.Footer>}
			</SC.DrawerBody>
		</RcDrawer>
	)
}

export default Drawer
