import React from 'react'
import { IconRegularSearch } from '@notino/react-styleguide'

// types
import { InputProps } from './types'

// styles
import * as SC from './InputStyles'

// TODO: dorobit potvrdenie na 'Enter'
const Input = (props: InputProps) => {
	const { inputMode, isPristine, suffix, onChange, allowClear, value, ...restProps } = props

	const isSearchMode = inputMode === 'search'

	const getSuffix = () => {
		if (isSearchMode && !value) {
			return suffix || <IconRegularSearch />
		}

		return suffix
	}

	const isAllowClear = allowClear || !!(isSearchMode && value)

	return (
		<SC.Input
			$isPristine={isPristine}
			$isSearch={isSearchMode}
			value={value}
			inputMode={inputMode}
			suffix={getSuffix()}
			onChange={onChange}
			allowClear={isAllowClear}
			{...restProps}
		/>
	)
}

export default React.memo(Input)
