/* eslint-disable global-require */
import styled from 'styled-components'
import { BREAKPOINTS } from '../../../../styles/constants'

// utils

export const SalonsPageBannerContainer = styled.div<{ $assetsPath: string }>`
	padding-bottom: 50%;
	height: 0;
	overflow: hidden;
	position: relative;
	background-size: auto 100%;
	background-position: center;

	@media (min-width: ${BREAKPOINTS.md}) {
		padding-bottom: 35%;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding-bottom: 340px;
		background-image: ${({ $assetsPath }) => `url("${`${$assetsPath}/${require(`../../../../assets/images/top-banner-background.jpg`)}`}")`};
	}
`

export const MainBannerContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	width: 100%;
	max-width: 1035px;
	transform: translateX(-50%);
`

export const Link = styled.a`
	width: 100%;
	height: 100%;
	text-decoration: none;
	display: block;
`

export const MainBanner = styled.div<{ $bannerDesktop: string; $bannerMobile: string }>`
	width: 100%;
	height: 100%;
	background-image: ${({ $bannerMobile }) => `url("${`${$bannerMobile}`}")`};
	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;

	@media (min-width: ${BREAKPOINTS.md}) {
		background-image: ${({ $bannerDesktop }) => `url("${`${$bannerDesktop}`}")`};
	}
`
