import React, { useContext, useEffect, useRef, useState, useCallback, ElementRef } from 'react'
import debounce from 'lodash.debounce'
import { IconSolidStar } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'
import { InfoWindow } from '@react-google-maps/api'
import * as SC from './SalonsMapStyles'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

// utils
import { MAP, SALONS_MAP_DEBOUNCE_TIME_MS } from '../../../../utils/enums'
import { ApiContext } from '../../../../utils/apiProvider'
import { MapBounds, ContextProps, MapPoints, Salon, SalonsPageQueryType, ServiceTotalPriceCurrencyCode } from '../../../../types/types'
import { formatUrlSlug, getCategoryIDs } from '../../../../utils/helper'
import { getMapViewItemListEvent } from '../../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

// components
import GoogleMaps, { GoogleMapPosition, MapMarker } from '../../../../components/GoogleMaps/GoogleMaps'
import Paths from '../../../../routes/paths'
import ListIcon from '../../../../assets/icons/ListIcon'
import SalonDetail from '../SalonDetail/SalonDetail'

// constants
const MAP_PADDING = 10
const TOGGLE_BUTTON_BOTTOM_OFFSET = 120 // offset of toggle button from bottom of container so it's OVER the cards (with some spacing)

// types
type Props = ContextProps & {
	assetsPath: string
	googleMapsApiKey: string
	query: SalonsPageQueryType
	topLevelCategoryID: string | undefined
	toggleMapView: () => void
	currencyCode: ServiceTotalPriceCurrencyCode | undefined
}

const SalonsMap = (props: Props) => {
	const { apiBrowser } = useContext(ApiContext)
	const { locale } = useIntl()
	const isDesktop = useMediaQuery(`(min-width: ${SALON_PAGE_MOBILE_BREAKPOINT})`)

	const { assetsPath, googleMapsApiKey, query, topLevelCategoryID, toggleMapView, currencyCode } = props
	const defaultLocation = MAP.locations[locale as keyof typeof MAP.locations] || MAP.defaultLocation

	const [currentMapPoints, setCurrentMapPoints] = useState<MapPoints | []>([])
	const [currentBounds, setCurrentBounds] = useState<MapBounds | null>(null)
	const [currentCenter, setCurrentCenter] = useState<GoogleMapPosition>(query.latMy && query.lonMy ? { lat: query.latMy, lng: query.lonMy } : defaultLocation)
	const [infoWindowCenter, setInfoWindowCenter] = useState(currentCenter)
	const [currentSalon, setCurrentSalon] = useState<Salon | null>(null)
	const [markersLoading, setMarkersLoading] = useState(true)
	const [mapState, setMapState] = useState<google.maps.Map | null>(null)

	const mapContainerRef = useRef<HTMLDivElement>(null)
	const mapRef = useRef<ElementRef<typeof GoogleMaps>>(null)
	const cardsContainerRef = useRef<HTMLDivElement | null>(null)
	const currentCardRef = useRef<HTMLAnchorElement | null>(null)
	const shouldRefetchSalonsRef = useRef(true)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleBoundsChanged = useCallback(
		debounce(() => {
			const bounds = mapState?.getBounds()
			if (!bounds) return

			// clicking a map's pin centers a map to that pin, which triggers
			// handleBoundsChanged - but in this situation we DON'T want to refetch salons
			// (it's so that mobile cards don't rerender and don't "jump")
			if (!shouldRefetchSalonsRef.current) {
				shouldRefetchSalonsRef.current = true
				return
			}

			const latNW = bounds.getNorthEast().lat()
			const lonNW = bounds.getSouthWest().lng()
			const latSE = bounds.getSouthWest().lat()
			const lonSE = bounds.getNorthEast().lng()

			setCurrentBounds({ latNW, lonNW, latSE, lonSE })
		}, SALONS_MAP_DEBOUNCE_TIME_MS),
		[mapState]
	)

	const handleInfoWindowCloseClick = () => {
		setCurrentSalon(null)
		mapRef.current?.resetCurrentMarker()
	}

	const handleMarkerClick = (marker: MapMarker) => {
		// zoom and center map to clicked marker
		shouldRefetchSalonsRef.current = false
		mapState?.setZoom(MAP.defaultZoom)
		mapState?.panTo(new google.maps.LatLng(marker.position.lat as number, marker.position.lng as number))

		setCurrentSalon(marker.extra as Salon)
		setInfoWindowCenter(marker.position)
	}

	useEffect(() => {
		return () => {
			handleBoundsChanged.cancel()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// scroll to map after triggering map view
	useEffect(() => {
		mapContainerRef?.current?.scrollIntoView({ block: 'center' })
	}, [mapContainerRef])

	// updating salons when bounds and filters change
	useEffect(() => {
		const refetchSalons = async () => {
			if (!currentBounds) return

			setMarkersLoading(true)
			const data = await apiBrowser.getSalonsDataForMap({
				...currentBounds,
				openingHoursStatus: query.openingHoursStatus,
				exactRating: query.exactRating,
				languageIDs: query.languageIDs,
				cosmeticIDs: query.cosmeticIDs,
				orderBy: query.orderBy,
				serviceTotalPriceFrom: query.serviceTotalPriceFrom,
				serviceTotalPriceTo: query.serviceTotalPriceTo,
				serviceTotalPriceCurrencyCode: currencyCode,
				categoryIDs: getCategoryIDs(query.categoryIDs, topLevelCategoryID),
				avResTimeSlotDate: query.avResTimeSlotDate,
				avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
				avResTimeSlotDateTo: query.avResTimeSlotDateTo
			})
			setMarkersLoading(false)
			if (!data) return

			const { mapPoints } = data
			setCurrentMapPoints(mapPoints)

			// push to dataLayer
			const event = getMapViewItemListEvent(mapPoints.map((point) => point.salon))
			pushToDataLayer(event)
		}
		refetchSalons()
	}, [query, apiBrowser, currentBounds, topLevelCategoryID, currencyCode])

	// updating map center when selected location (city) changes
	useEffect(() => {
		if (query.latMy && query.lonMy) {
			setCurrentCenter({ lat: query.latMy, lng: query.lonMy })
		}
	}, [query.latMy, query.lonMy])

	// cards scrolling
	useEffect(() => {
		if (!currentSalon) return

		const containerElement = cardsContainerRef?.current
		const cardElement = currentCardRef?.current
		if (!containerElement || !cardElement) return

		const leftOffsetWithPadding = (cardElement.offsetLeft ?? 0) - MAP_PADDING
		containerElement.scrollLeft = leftOffsetWithPadding
	}, [currentSalon])

	const markers: MapMarker[] = currentMapPoints.map((mapPoint) => ({
		id: mapPoint.salon.id,
		position: { lat: mapPoint.lat, lng: mapPoint.lon },
		extra: mapPoint.salon
	}))

	return (
		<SC.GoogleMapContainer ref={mapContainerRef} $mapPadding={MAP_PADDING}>
			<GoogleMaps
				ref={mapRef}
				assetsPath={assetsPath}
				googleMapsApiKey={googleMapsApiKey}
				center={currentCenter}
				markers={markers}
				onMarkerClick={handleMarkerClick}
				onClick={() => setCurrentSalon(null)} // closing salon detail on click outside of salon detail
				onLoad={(map) => setMapState(map)}
				onBoundsChanged={debounce(handleBoundsChanged, 400)}
				markersLoading={markersLoading}
			>
				{/* salon desktop popup windows */}
				{/* NOTE: using useMediaQuery instead of hidding InfoWindow on desktop with CSS because it's very hard to style it (and thus hide it) */}
				{isDesktop && currentSalon && (
					<InfoWindow position={infoWindowCenter} options={{ pixelOffset: new google.maps.Size(0, -20) }} onCloseClick={handleInfoWindowCloseClick}>
						{currentSalon && (
							<SC.SalonDetailWrapper>
								<SalonDetail {...props} isLoading={false} salon={currentSalon} />
							</SC.SalonDetailWrapper>
						)}
					</InfoWindow>
				)}
			</GoogleMaps>

			{/* salon mobile cards */}
			{!isDesktop && mapState && (
				<SC.ButtonAndCardsContainer $mapPadding={MAP_PADDING}>
					<SC.ToggleListButtonMobile
						onClick={toggleMapView}
						$mapPadding={MAP_PADDING}
						// button over cards when cards are rendered, otherwise button is at the bottom of the container
						$bottom={currentMapPoints.length > 0 ? TOGGLE_BUTTON_BOTTOM_OFFSET : 0}
					>
						<ListIcon />
					</SC.ToggleListButtonMobile>
					{currentMapPoints.length > 0 && (
						<SC.CardsContainer ref={cardsContainerRef} $mapPadding={MAP_PADDING}>
							{currentMapPoints.map((mapPoint) => {
								const salonHref = `${Paths[locale as keyof typeof Paths]['/salons']}${formatUrlSlug(mapPoint.salon.seoSlugName)}`
								const salonCategories = mapPoint.salon.categories
								const numberOfAdditionalCategories = salonCategories.length
								return (
									<SC.SalonCard
										href={salonHref}
										key={mapPoint.salon.id}
										ref={mapPoint.salon.id === currentSalon?.id ? currentCardRef : null}
										$isSelected={mapPoint.salon.id === currentSalon?.id}
									>
										<SC.SalonCardImageWrapper>
											<SC.SalonCardImage salon={mapPoint.salon} assetsPath={assetsPath} />
										</SC.SalonCardImageWrapper>
										<SC.SalonCardTextContent>
											<SC.SalonCardSalonName>{mapPoint.salon.name}</SC.SalonCardSalonName>
											{salonCategories.length > 0 && (
												<SC.SalonCardDescription>
													{salonCategories[0]?.name} {numberOfAdditionalCategories > 0 && `+${numberOfAdditionalCategories}`}
												</SC.SalonCardDescription>
											)}
											{mapPoint.salon.rating && (
												<SC.Rating>
													<IconSolidStar width='12' />
													<SC.RatingValue>{mapPoint.salon.rating}</SC.RatingValue>
												</SC.Rating>
											)}
										</SC.SalonCardTextContent>
									</SC.SalonCard>
								)
							})}
						</SC.CardsContainer>
					)}
				</SC.ButtonAndCardsContainer>
			)}
		</SC.GoogleMapContainer>
	)
}

export default SalonsMap
