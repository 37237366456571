import { theme } from '@notino/react-styleguide'
import styled from 'styled-components'
import { SALON_DETAIL_RIGHT_CONTACT_BAR, SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const SalonContactsContainer = styled.div`
	padding: 22px;
	border: 1px solid ${theme.color.border.divider};

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		width: ${SALON_DETAIL_RIGHT_CONTACT_BAR}px;
		min-width: ${SALON_DETAIL_RIGHT_CONTACT_BAR}px;
	}

	@media (max-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		padding: 0;
		width: 100%;
		border: none;
	}
`

export const InfoContainer = styled.div`
	&:not(:last-child) {
		padding-bottom: 42px;
	}
`

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`

export const Title = styled.div`
	${theme.typography.titleSmall}
	margin-left: 10px;
	margin-top: 2px;
`

export const AddressContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 8px;
`

export const DataContainer = styled.div`
	${theme.typography.bodyLarge}
	margin-left: 34px;
	margin-bottom: 8px;

	@media (max-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		width: 100%;
		margin-left: 0;
	}
`

export const MapLinkDesktop = styled.a`
	display: none;
	color: inherit;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		display: block;
	}
`

export const LinkButtonMobile = styled.a`
	float: right;
	display: inline-flex;
	align-items: center;
	gap: 8px;
	background: black;
	padding: 8px;
	color: white;
	line-height: 0; // resetting notino default line height inherited from body, otherwise element won't be squared
	text-decoration: none;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}

	& > svg {
		width: 16px;
		height: 16px;
	}
`

export const BasicSalonLinkWrapper = styled.div`
	margin-top: 8px;
`

export const BasicSalonLink = styled.a`
	color: inherit;
`

export const ContactInfoEmail = styled.div`
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (max-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		width: auto;
		overflow: auto;
	}
`
