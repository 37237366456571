import React, { useEffect } from 'react'
import { Head } from '@notino/react-toolkit/exports/Head'
import { useIntl } from 'react-intl'
import { shopsConfig } from '../../../../appDefaults'
import Paths from '../../../../routes/paths'
import useMessages from '../../../../hooks/useMessages'
import { CategoryNameLocalization, SalonsPagination } from '../../../../types/types'
import { formatUrlSlug } from '../../../../utils/helper'

type Props = {
	pagination: SalonsPagination | undefined
	categoryLocalizations: CategoryNameLocalization[] | undefined
	showSubcategories: boolean
	categoryName?: string
}

const SalonsHead = (props: Props) => {
	const { locale } = useIntl()
	const { messages } = useMessages()

	const { pagination, categoryLocalizations, showSubcategories, categoryName } = props

	const defaultShop = shopsConfig[0]

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)
	const currentCategoryLocalizations = categoryLocalizations?.find((localization) => localization.language === locale)
	const currentCategoryDefaultLocalizations = categoryLocalizations?.find((localization) => localization.language === shopsConfig[0].locale)
	const currentCategoryLocalizationsSlug = formatUrlSlug(currentCategoryLocalizations?.valueSlug)

	const currentShopSalonsPath = currentShop ? `${currentShop.url}${Paths[currentShop.locale as keyof typeof Paths]['/salons']}` : null
	const currentShopSalonsServicesPath = currentShop ? `${currentShop.url}${Paths[currentShop.locale as keyof typeof Paths]['/salons/services']}` : null

	// canonical href
	let canonicalHref: string | undefined
	if (showSubcategories) {
		canonicalHref =
			currentShopSalonsServicesPath && currentCategoryLocalizationsSlug
				? `${currentShopSalonsServicesPath}${currentCategoryLocalizationsSlug}`
				: undefined
	} else {
		canonicalHref = currentShopSalonsPath || undefined
	}

	// alternate hrefs
	const alternateLinks = shopsConfig
		.map((shop) => {
			if (showSubcategories) {
				const categoryLocalization = categoryLocalizations?.find((localization) => localization.language === shop.locale)
				const categorySlug = formatUrlSlug(categoryLocalization?.valueSlug)
				if (!categorySlug) return null
				return {
					href: `${shop.url}${Paths[shop.locale as keyof typeof Paths]['/salons/services']}${categorySlug}`,
					lang: shop.lang
				}
			}
			return {
				href: `${shop?.url}${Paths[shop.locale as keyof typeof Paths]['/salons']}`,
				lang: shop.lang
			}
		})
		.filter(Boolean)

	// x-default href
	let xDefaultHref: string | undefined
	if (showSubcategories) {
		xDefaultHref = currentCategoryDefaultLocalizations?.valueSlug
			? `${defaultShop.url}${Paths[defaultShop.locale as keyof typeof Paths]['/salons/services']}${formatUrlSlug(
					currentCategoryDefaultLocalizations.valueSlug
				)}`
			: undefined
	} else {
		xDefaultHref = `${defaultShop.url}${Paths[defaultShop.locale as keyof typeof Paths]['/salons']}`
	}

	// prev link
	const hasLessPages = pagination && pagination.page !== 1

	let prevLink: string | undefined
	if (showSubcategories && hasLessPages) {
		prevLink =
			currentShopSalonsServicesPath && currentCategoryLocalizationsSlug
				? `${currentShopSalonsServicesPath}${currentCategoryLocalizationsSlug}?page=${pagination.page - 1}`
				: undefined
	}
	if (!showSubcategories && hasLessPages) {
		prevLink = currentShopSalonsPath ? `${currentShopSalonsPath}?page=${pagination.page - 1}` : undefined
	}

	// next link
	const hasMorePages = pagination && pagination.page !== pagination.totalPages

	let nextLink: string | undefined
	if (showSubcategories && hasMorePages) {
		nextLink =
			currentShopSalonsServicesPath && currentCategoryLocalizationsSlug
				? `${currentShopSalonsServicesPath}${currentCategoryLocalizationsSlug}?page=${pagination.page + 1}`
				: undefined
	}
	if (!showSubcategories && hasMorePages) {
		nextLink = currentShopSalonsPath ? `${currentShopSalonsPath}?page=${pagination.page + 1}` : undefined
	}

	// page title - category name or general title
	const pageTitle = `${categoryName ?? messages?.['Salons page SEO title']} | Notino Partner`

	// use effect needed to change title in the browser tab when category changes during client side navigation
	useEffect(() => {
		document.title = pageTitle
	}, [pageTitle])

	// NOTE: defining children as array and using .flat() on it otherwise <Head> doesn't work properly
	// SIDENOTE: each item has to have unique 'key' because <Head/> iterates over the array
	const children = [
		// charset
		<meta key='charset' charSet='utf-8' />,

		// title
		<title key='title'>{pageTitle}</title>,

		// description
		<meta key='description' name='description' content={messages?.['Salons page SEO description'] as string} />,

		// keywords
		<meta key='keywords' name='keywords' content={messages?.['Salons page SEO keywords'] as string} />,

		// canonical
		canonicalHref && <link key='canonical' rel='canonical' href={canonicalHref} />,
		// alternates
		alternateLinks.map((link, id) => {
			return <link key={`alternate-${id}`} rel='alternate' href={link?.href} hrefLang={link?.lang} />
		}),
		// x-default
		xDefaultHref && <link key='x-default' rel='alternate' href={xDefaultHref} hrefLang='x-default' />,

		// pagination SEO links
		prevLink && <link key='prev' rel='prev' href={prevLink} />,
		nextLink && <link key='next' rel='next' href={nextLink} />
	]
		.flat()
		.filter(Boolean)

	return <Head>{children}</Head>
}

export default SalonsHead
