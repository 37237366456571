import styled, { css } from 'styled-components'
import { RadioButton as NotinoRadioButton } from '@notino/react-styleguide'

// utils
import { NotinoLabelRegular400, NotinoLabelRegular500 } from '../../styles/helpers'

export const RadioButtonsWrapper = styled.fieldset`
	display: flex;
	flex-direction: column;
	gap: 12px;
	border: none;
`

export const RadioButton = styled(NotinoRadioButton)`
	min-height: 36px;
	display: flex;
	align-items: center;

	// checkbox
	& > *:last-child {
		top: 50%;
		transform: translateY(-50%);
	}
`

export const RadioButtonLabel = styled.span`
	display: inline-flex;
	width: 100%;
	align-items: center;
`

export const RadioButtonLabelTitle = styled.span<{ $checked?: boolean }>`
	${({ $checked }) =>
		$checked
			? css`
					color: #000;
					${NotinoLabelRegular500};
				`
			: css`
					color: #474747;
					${NotinoLabelRegular400}
				`};
`

export const RadioButtonLabelDescription = styled.span`
	margin-left: auto;
	text-align: right;
	color: #6b6b6b;
	${NotinoLabelRegular400};
`
