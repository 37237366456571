import { theme } from '@notino/react-styleguide'
import styled from 'styled-components'

const ICON_SIZE = 24

export const Container = styled.div`
	${theme.typography.bodyLarge}
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 10px;
`

export enum SOCIAL_LINK_VARIANT {
	LIGHT = 'LIGHT',
	DARK = 'DARK'
}
export const SocialLink = styled.a<{ $variant: SOCIAL_LINK_VARIANT }>`
	padding: 8px;
	line-height: 0; // resetting notino default line height inherited from body, otherwise element won't be squared

	border: 1px solid ${(p) => (p.$variant === SOCIAL_LINK_VARIANT.LIGHT ? theme.color.common.white : theme.color.border.selected)};

	svg {
		width: ${ICON_SIZE}px;
		height: ${ICON_SIZE}px;
		color: ${(p) => (p.$variant === SOCIAL_LINK_VARIANT.LIGHT ? theme.color.icon.inverse : theme.color.icon.primary)};
	}
`
