import { IconRegularChevronLeft, IconRegularChevronRight } from '@notino/react-styleguide'
import React, { useEffect, useRef, useState } from 'react'
import useScrolledElement from '../../../../hooks/useScrolledElement'
import { SalonServicesResponse } from '../../../../types/types'
import { isElementOverflownX } from '../../../../utils/helper'
import * as SC from './SalonServicesTabsStyles'
import SalonServicesCards from '../SalonServicesCards/SalonServicesCards'

type Props = {
	services?: SalonServicesResponse
}

const SalonServicesTabs = ({ services }: Props) => {
	const scrollElement = useRef<HTMLUListElement | null>(null)
	const { isScrolledLeft, isScrolledRight, scrollLeft, scrollRight } = useScrolledElement(scrollElement.current)
	const [isOverflown, setIsOverflown] = useState(false)

	useEffect(() => {
		const checkOverflow = () => {
			if (scrollElement.current) {
				setIsOverflown(isElementOverflownX(scrollElement.current))
			}
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)

		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [scrollElement])

	const [tabKey, setTabKey] = useState<string | undefined>(services?.groupedServicesByCategory[0].category?.id || undefined)

	return (
		<SC.ServicesTabsWrapper>
			<SC.TabsContainer>
				{isOverflown && isScrolledLeft && (
					<SC.ScrollLeftButton type='button' onClick={scrollLeft} style={{ zIndex: 10 }}>
						<IconRegularChevronLeft color={'icon.primary'} />
					</SC.ScrollLeftButton>
				)}
				<SC.Tabs role={'tablist'} ref={scrollElement}>
					{services?.groupedServicesByCategory?.map((catObj) => {
						if (!catObj.category) {
							return null
						}
						const key = catObj.category?.id
						const isActive = tabKey === key

						return (
							<SC.Tab
								key={key}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										setTabKey(key)
									}
								}}
							>
								<SC.TabButton $isActive={isActive} tabIndex={0} role={'tab'} aria-selected={isActive} onClick={() => setTabKey(key)}>
									{catObj.category?.name}
								</SC.TabButton>
							</SC.Tab>
						)
					})}
				</SC.Tabs>
				{isOverflown && !isScrolledRight && (
					<SC.ScrollRightButton type='button' onClick={scrollRight} style={{ zIndex: 10 }}>
						<IconRegularChevronRight color={'icon.primary'} />
					</SC.ScrollRightButton>
				)}
			</SC.TabsContainer>
			<SC.TabsContent>
				{services?.groupedServicesByCategory?.map((catObj) => {
					if (!catObj.category) {
						return null
					}

					const key = catObj.category?.id
					const isActive = tabKey === key

					return (
						<SC.TabContent key={key} $isActive={isActive}>
							<SalonServicesCards firstCategoryData={catObj?.category?.children} />
						</SC.TabContent>
					)
				})}
			</SC.TabsContent>
		</SC.ServicesTabsWrapper>
	)
}

export default SalonServicesTabs
