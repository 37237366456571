import React from 'react'
import { Breadcrumb as NotinoBreadcrumb, IBreadcrumb } from '@notino/react-styleguide'
import { useLocation } from 'react-router-dom'
import useIsMobile from '../../hooks/useIsMobile'
import { SALONS_PAGE_MOBILE_BREAKPOINT_INT } from '../../styles/constants'
import { pushToDataLayer } from '../../utils/dataLayer'
import { getBreadcrumbClickEvent } from '../../utils/dataLayerEvents'
import * as SC from './BreadcrumbStyles'

type BreadcrumbProps = {
	breadcrumbItems: IBreadcrumb[]
	handleNavigation?: (item: IBreadcrumb) => void
}

const Breadcrumb = (props: BreadcrumbProps) => {
	const { breadcrumbItems, handleNavigation } = props
	const location = useLocation()
	const isMobile = useIsMobile(SALONS_PAGE_MOBILE_BREAKPOINT_INT)

	const handleItemClick = (params: { index: number } & IBreadcrumb) => {
		// push to dataLayer
		const event = getBreadcrumbClickEvent({
			isMobile,
			name: params.name,
			pathQuery: `${location.pathname}/${location.search}`,
			levelIndex: params.index,
			text: breadcrumbItems.map((item) => item.name)
		})
		pushToDataLayer(event)
	}

	return (
		<SC.BreadcrumbWrapper>
			<NotinoBreadcrumb homeLinkTitle='Home page' items={breadcrumbItems} onItemClick={handleItemClick} handleNavigation={handleNavigation} />
		</SC.BreadcrumbWrapper>
	)
}
export default Breadcrumb
