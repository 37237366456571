import { theme } from '@notino/react-styleguide'
import styled from 'styled-components'
import { NotinoBodyLarge, NotinoLabelRegular, NotinoLabelRegular400, NotinoTitleDisplay } from '../../../../styles/helpers'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const TabsContainer = styled.div`
	.rc-tabs {
		display: flex;
		overflow: hidden;
		flex-direction: column;
	}
	.rc-tabs-ink-bar {
		display: none;
	}
	.rc-tabs-tabpane-hidden {
		display: none;
	}
	.rc-tabs-nav-operations {
		display: none;
	}
	.rc-tabs-content-holder {
		flex: auto;

		.rc-tabs-content {
			position: relative;
			width: 100%;
		}
	}
	.rc-tabs-nav-wrap {
		position: relative;
		display: inline-block;
		flex: auto;
		white-space: nowrap;
		overflow: hidden;
		display: flex;
		margin-bottom: 24px;

		&::before {
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			bottom: 0px;
			height: 1px;
			background: ${theme.color.background.tertiary};
			z-index: 1;
		}

		.rc-tabs-nav-list {
			display: flex;
			width: 100%;
			gap: 28px;
			overflow-x: auto;
			z-index: 2;
			position: relative;

			.rc-tabs-tab {
				border: 0;
				margin: 0;
				display: flex;
				outline: none;
				cursor: pointer;
				position: relative;
				align-items: center;
				white-space: nowrap;
				padding: 8px 0;

				.rc-tabs-tab-btn {
					${NotinoLabelRegular400};
					border: 0;
					background: transparent;
				}

				&.rc-tabs-tab-active {
					.rc-tabs-tab-btn {
						${NotinoLabelRegular};
					}
					&::after {
						position: absolute;
						content: '';
						left: 0;
						right: 0;
						bottom: 0px;
						height: 2px;
						background: #000;
					}
				}
			}
		}
	}
`

export const OpeningHoursWrapper = styled.div`
	padding-bottom: 16px;
	border-bottom: 1px solid ${theme.color.border.divider};
`

export const ServicesWrapper = styled.div`
	padding-top: 24px;
	border-bottom: 1px solid ${theme.color.border.divider};
`

export const AboutUsWrapper = styled.div`
	padding-top: 24px;
`

export const AboutUsText = styled.p`
	${NotinoBodyLarge};
	line-height: 1.75rem;
	margin-bottom: 24px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 16px;
	}
`

export const ServicesHeading = styled.div`
	${NotinoTitleDisplay};
	margin-bottom: 16px;
`

export const Columns = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	column-gap: 40px;
	margin-bottom: 60px;
`

export const MainColumn = styled.div`
	overflow: hidden; // services tab might oveflow - in that case, we want to scroll services buttons, for which we need overflow on SalonInfo
	flex: 1 1 0;
`

export const SideColumn = styled.div``
