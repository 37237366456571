import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IconRegularPin, IconRegularSearch } from '@notino/react-styleguide'
import debounce from 'lodash.debounce'
import { useIntl } from 'react-intl'
import { components, GroupBase, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectComponents } from 'react-select/dist/declarations/src/components'

// hooks
import useMessages from '../../../../hooks/useMessages'
import useIsMobile from '../../../../hooks/useIsMobile'

// types
import { CitiesType, CityType, ContextProps } from '../../../../types/types'

// styles
import * as SC from './SalonsSearchStyles'
import { customSelectStyles } from './SalonsSearchStyles'
import { SALONS_PAGE_MOBILE_BREAKPOINT_INT } from '../../../../styles/constants'

// utils
import Paths from '../../../../routes/paths'
import { formatUrlSlug } from '../../../../utils/helper'
import { ApiContext } from '../../../../utils/apiProvider'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { getPlaceSelectedEvent, getSalonSelectedFromSearchEvent, getSearchFocusedEvent } from '../../../../utils/dataLayerEvents'
import { SALONS_SEARCH_DEBOUNCE_TIME_MS, SEARCH_FIELD_OPTION_TYPE } from '../../../../utils/enums'

// assets
import MapIcon from '../../../../assets/icons/MapIcon'

// types
type CustomOption = {
	value: string
	label: string
	type?: SEARCH_FIELD_OPTION_TYPE
	address?: string
	seoSlugName?: string
}

type SalonsSearchType = ContextProps & {
	defaultValue: string
	onChange: (city?: CityType | null) => void
}

type SelectCustomComponents = Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>

// components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<IconRegularSearch color={'icon.secondary'} width='12' height='12' />
			</components.DropdownIndicator>
		)
	)
}

const Option = (optionProps: OptionProps<CustomOption> & { inputCurrentValue: string }) => {
	const { inputCurrentValue } = optionProps
	const { locale } = useIntl()

	const handleSalonClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		// when option link is clicked, option is filled out for split second and only AFTER that we are taken to href location - to avoid this we stop propagation
		e.stopPropagation()

		// push to dataLayer
		if (optionProps.data?.type === SEARCH_FIELD_OPTION_TYPE.SALON) {
			const event = getSalonSelectedFromSearchEvent({ inputValue: inputCurrentValue })
			pushToDataLayer(event)
		}
	}

	return (
		<components.Option {...optionProps}>
			{optionProps.data?.type === SEARCH_FIELD_OPTION_TYPE.CITY && (
				<SC.OptionContent>
					<SC.OptionIconWrapper>
						<MapIcon />
					</SC.OptionIconWrapper>
					<span>{optionProps.label}</span>
				</SC.OptionContent>
			)}
			{optionProps.data?.type === SEARCH_FIELD_OPTION_TYPE.SALON && (
				<SC.OptionContent>
					<SC.OptionLink
						href={`${Paths[locale as keyof typeof Paths]['/salons']}${formatUrlSlug(optionProps?.data.seoSlugName)}`}
						onClick={handleSalonClick}
					>
						<div>
							<SC.OptionIconWrapper>
								<IconRegularPin width='14' height='14' color={'icon.secondary'} />
							</SC.OptionIconWrapper>
							<span className='salon-name'>{optionProps.label}</span>
						</div>
						<SC.OptionAddress> {optionProps.data?.address}</SC.OptionAddress>
					</SC.OptionLink>
				</SC.OptionContent>
			)}
		</components.Option>
	)
}

// component
const SalonsSearch: React.FC<React.PropsWithChildren<SalonsSearchType>> = (props) => {
	const { onChange, defaultValue } = props
	const [currentCities, setCurrentCities] = useState<CitiesType>([])
	const [inputCurrentValue, setInputCurrentValue] = useState<string>('')
	const [currentValue, setCurrentValue] = useState<CustomOption | null>(defaultValue ? { value: defaultValue, label: defaultValue } : null)
	const isMobile = useIsMobile(SALONS_PAGE_MOBILE_BREAKPOINT_INT)
	const { locale } = useIntl()
	const { messages } = useMessages()

	// get api client for requests outside of server
	const { apiBrowser } = useContext(ApiContext)

	const handleChange = (value: unknown) => {
		const newValue = value as CustomOption | null
		setCurrentValue(newValue)

		// NOTE: hacky solution for situation when user selects salon using keyboard and then presses
		// enter which doesn't trigger link navigation using href set in Option component. This solution
		// is still imperfect because the search input is filled out with salon name and only after that
		// app navigates to salon detail page.
		if (newValue?.type === SEARCH_FIELD_OPTION_TYPE.SALON) {
			window.location.href = `${Paths[locale as keyof typeof Paths]['/salons']}${formatUrlSlug(newValue.seoSlugName)}`
			return
		}

		// push to dataLayer
		const event = getPlaceSelectedEvent({ inputValue: inputCurrentValue }) // NOTE: Notino wants to track input value, not selected value
		pushToDataLayer(event)

		if (newValue) {
			const placeId = newValue.value
			const foundCity = currentCities.find((city) => city.placeID === placeId)
			onChange(foundCity)
		} else {
			onChange(null) // resetting value
		}
	}

	const selectComponents: SelectCustomComponents = {
		IndicatorSeparator: () => null,
		DropdownIndicator: currentValue ? () => null : DropdownIndicator,
		// TODO: fix types for option and remove lint and TS ignores
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Option: (optionProps: OptionProps) => <Option {...optionProps} inputCurrentValue={inputCurrentValue} />
	}

	const handleFocus = () => {
		// push to dataLayer
		const event = getSearchFocusedEvent({ isMobile })
		pushToDataLayer(event)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loadOptionsDebounced = useCallback(
		debounce((inputValue: string, callback: (options: CustomOption[]) => void) => {
			apiBrowser.getSalonsFilterData(inputValue).then((salonsData) => {
				if (!salonsData) return []
				setCurrentCities(salonsData.cities)

				const citiesOptions = salonsData.cities.map((city: { latitude?: number; longitude?: number; name: string; placeID: string }) => ({
					value: city.placeID,
					label: city.name,
					type: SEARCH_FIELD_OPTION_TYPE.CITY
				}))
				const salonsOptions = salonsData.salons.map((salon) => ({
					value: salon.id,
					label: salon.name as string,
					type: SEARCH_FIELD_OPTION_TYPE.SALON,
					address: `${salon.address?.street}, ${salon.address?.city}`,
					seoSlugName: salon.seoSlugName
				}))

				const options = [...citiesOptions, ...salonsOptions]

				return callback(options)
			})
		}, SALONS_SEARCH_DEBOUNCE_TIME_MS),
		[]
	)

	useEffect(() => {
		return () => {
			loadOptionsDebounced.cancel()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!defaultValue) {
			setCurrentValue(null)
		} else {
			const placeIdToCityName = async (placeId: string) => {
				const city = await apiBrowser.getCityNameByPlaceId(placeId)

				if (city) {
					setCurrentValue({ value: city.placeID, label: city.name || '-' })
				} else {
					setCurrentValue(null)
				}
			}

			placeIdToCityName(defaultValue)
		}
	}, [defaultValue, apiBrowser])

	return (
		<AsyncSelect
			instanceId={'salons-search-input'}
			placeholder={`${messages?.['Search for a city or a salon']}...`}
			loadOptions={loadOptionsDebounced}
			styles={customSelectStyles}
			components={selectComponents}
			onChange={handleChange}
			onInputChange={(newValue) => setInputCurrentValue(newValue)}
			loadingMessage={() => `${messages?.Loading}...`}
			noOptionsMessage={() => (inputCurrentValue ? messages?.['No results'] : null)}
			value={currentValue}
			isClearable={true}
			backspaceRemovesValue
			onFocus={handleFocus}
		/>
	)
}

export default SalonsSearch
