import React, { PropsWithChildren } from 'react'
import { Spinner } from '@notino/react-styleguide'

// assets
import EmptyStateIcon from '../../../../assets/icons/EmptyStateIcon'

// hooks
import useMessages from '../../../../hooks/useMessages'

// styles
import * as SC from './FetchResultStyles'

// types
type FetchResultProps = {
	initialLoading?: boolean
	isRefetching?: boolean
	isError?: boolean
	empty?: boolean
	emptyLabel?: React.ReactNode
	emptyIcon?: React.ReactNode
	className?: string
} & PropsWithChildren

// component
const FetchResult = (props: FetchResultProps) => {
	const { children, isError, initialLoading, isRefetching, empty, emptyLabel, emptyIcon = <EmptyStateIcon />, className } = props

	const { messages } = useMessages()

	return (
		<SC.FetchResultWrapper className={className}>
			{(() => {
				if (initialLoading) {
					return (
						<SC.StateWrapper>
							<Spinner color={'icon.primary'} />
						</SC.StateWrapper>
					)
				}

				if (isError) {
					return (
						<SC.StateWrapper>
							<SC.EmptyIconWrapper>{emptyIcon}</SC.EmptyIconWrapper>
							<SC.StateLabel>{emptyLabel || messages?.['Something went wrong. Data was not leaded.']}</SC.StateLabel>
						</SC.StateWrapper>
					)
				}

				if (empty) {
					return (
						<SC.StateWrapper>
							<SC.EmptyIconWrapper>{emptyIcon}</SC.EmptyIconWrapper>
							<SC.StateLabel>{emptyLabel || messages?.['Unfortunately, no data was found']}</SC.StateLabel>
						</SC.StateWrapper>
					)
				}

				return (
					<SC.SpinWrapper>
						{isRefetching && (
							<SC.SpinnerWrapper>
								<SC.Spinner size={24} color={'icon.primary'} />
							</SC.SpinnerWrapper>
						)}
						<SC.SpinContent $isRefetching={isRefetching}>{children}</SC.SpinContent>
					</SC.SpinWrapper>
				)
			})()}
		</SC.FetchResultWrapper>
	)
}

export default FetchResult
