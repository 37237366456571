import { Button } from '@notino/react-styleguide'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from '../../../../styles/constants'

export const ImageGridWrapper = styled.div`
	position: relative;
	margin-bottom: 64px;

	&:hover {
		cursor: pointer;
	}
`

export const ImageGrid = styled.div<{ $numberOfImages: number }>`
	position: relative;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	grid-template-rows: 50% 50%;
	gap: 4px;

	height: 320px;
	max-height: 320px;

	@media (min-width: ${BREAKPOINTS['3xl']}) {
		height: 400px;
		max-height: 400px;
	}

	img {
		height: 100%;
		object-fit: cover;
	}

	${({ $numberOfImages }) => {
		/* one image */
		if ($numberOfImages === 1) {
			return css`
				& > div:first-child {
					grid-column: 1 / 4;
					grid-row: 1 / 3;
				}
			`
		}

		/* two images */
		if ($numberOfImages === 2) {
			return css`
				& > div:first-child {
					grid-column: 1 / 3;
					grid-row: 1 / 3;
				}

				& > div:nth-child(2) {
					grid-column: 3 / 4;
					grid-row: 1 / 3;
				}
			`
		}

		/* three images */
		if ($numberOfImages === 3) {
			return css`
				& > div:first-child {
					grid-column: 1 / 3;
					grid-row: 1 / 3;
				}

				& > div:nth-child(2) {
					grid-column: 3 / 4;
					grid-row: 1 / 2;
				}

				& > div:nth-child(3) {
					grid-column: 3 / 4;
					grid-row: 2 / 3;
				}
			`
		}

		/* four images */
		if ($numberOfImages === 4) {
			return css`
				& > div:first-child {
					grid-column: 1 / 2;
					grid-row: 1 / 3;
				}

				& > div:nth-child(2) {
					grid-column: 2 / 3;
					grid-row: 1 / 3;
				}

				& > div:nth-child(3) {
					grid-column: 3 / 4;
					grid-row: 1 / 2;
				}

				& > div:nth-child(4) {
					grid-column: 3 / 4;
					grid-row: 2 / 3;
				}
			`
		}

		/* five images */
		if ($numberOfImages >= 5) {
			return css`
				& > div:first-child {
					grid-column: 1 / 2;
					grid-row: 1 / 3;
				}

				& > div:nth-child(2) {
					grid-column: 2 / 3;
					grid-row: 1 / 2;
				}

				& > div:nth-child(3) {
					grid-column: 3 / 4;
					grid-row: 1 / 2;
				}

				& > div:nth-child(4) {
					grid-column: 2 / 3;
					grid-row: 2 / 3;
				}

				& > div:nth-child(5) {
					grid-column: 3 / 4;
					grid-row: 2 / 3;
				}
			`
		}

		return css``
	}}
`

export const LightboxOpenButton = styled(Button)`
	position: absolute;
	bottom: 10px;
	right: 10px;
	padding: 4px 6px;
	display: flex;
	align-items: center;
	gap: 8px;
`

export const MainImageWrapper = styled.div`
	height: 70vh;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
`

export const ModalImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

export const ThumbnailButtonsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`

export const ThumbnailButton = styled.button`
	height: 70px;
	width: 70px;
	cursor: pointer;
	background: none;
	border: none;
`

export const ModalWrapper = styled.div`
	/* there's no other way how to access Notino modal styles than wrapping it and selecting child (with role=dialog) */
	& div[role='dialog'] {
		position: relative; // needed for close button
		padding-top: 32px; // equals to modals left and right padding

		/* fix for close button taking the entire width of the modal (visible when focused) */
		/* there's no other way how to access Notino's modal close button than using following selector */
		& > button {
			position: absolute;
			top: 12px;
			right: 12px;
			width: 32px;
			height: 32px;
			display: initial; // reset from flex - icon's alignment was broken for flex

			& > svg {
				margin-right: 0;
			}
		}
	}
`
