import { theme } from '@notino/react-styleguide'
import { CSSObjectWithLabel, StylesConfig } from 'react-select'
import styled from 'styled-components'

export const customSelectStyles: StylesConfig = {
	control: (styles, state) =>
		({
			...styles,
			borderRadius: '0px',
			borderColor: state.isFocused ? theme.color.border.selected : theme.color.border.divider,
			boxShadow: 'none',
			'&:hover': {
				border: `1px solid ${theme.color.border.selected}`,
				cursor: 'text'
			}
		}) as CSSObjectWithLabel,
	option: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			borderRadius: 0,
			backgroundColor: isSelected ? 'gray' : isFocused ? 'darkgray' : undefined, // eslint-disable-line no-nested-ternary
			'&:active': {
				background: 'gray',
				color: 'white'
			},
			padding: 0 // setting padding on option's child
		} as CSSObjectWithLabel
	},
	menu: (styles) => ({ ...styles, borderRadius: 0 }) as CSSObjectWithLabel,
	clearIndicator: (styles) => ({ ...styles, cursor: 'pointer' }) as CSSObjectWithLabel
}

export const OptionContent = styled.div`
	padding: 8px 12px;
`

export const OptionLink = styled.a`
	color: inherit;
	text-decoration: none;
	display: block;

	&:hover .salon-name {
		text-decoration: underline;
	}
`

export const OptionIconWrapper = styled.span`
	margin-right: 8px;
`

export const OptionAddress = styled.div`
	color: ${theme.color.text.tertiary};
	margin-left: 22px;
	margin-top: 4px;
	font-size: 14px;
`
